/**
 * Basket Elements.
 */

.basket {
    @extend %block;
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    margin-top: 20px;
    border: 1px solid #dedede;
    padding: 15px;

    &-code {
        @extend %block;
        max-width: 275px;
        background: #f0f0f0;
        padding: 7px;
        display: none;

        &__input,
        &__button {
            @extend %reset;
            display:inline-block;
            vertical-align: middle;
        }

        &__input {
            border: 1px solid #d1d1d1;
            border-right: none;
            height: 34px;
            line-height: 34px;
            background: #FFFFFF;
            padding: 0 10px;
            font-size: 12px;
            color: #7c7c7c;
            width: calc(100% - 112px);
            @include montserrat();
            margin:0;
        }

        &__button {
            width: 90px;
            height: 36px;
            border: 1px solid #d1d1d1;
            border-left: none;
            line-height: 36px;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
            display:inline-block!important;
            background:#04aae7;

            &:hover {
                background:#04aae7;
            }
        }

        &--inline {
            display: block;

            @media (max-width: 1000px) {
                display: none;
            }
        }

        &--mobile {
            @media (max-width: 1000px) {
                display: block;
                margin: 0 auto;
                margin-top: 15px;
            }
        }
    }

    &__button {
        @extend %block;
        margin-top: 9px;
        text-align: right;

        .button {
            width: 195px;
            padding-left: 0;
            padding-right: 0;
            transition: width 0.5s ease;

            &:first-child {
                float: left;
                background:#04aae7;
            }

            &:last-child {
                float:right;
                background:$dark-blue;
            }

            @media (max-width: 660px) {
                width: 100%;
                display: block;
                margin-top: 5px;
                max-width:100%;
                line-height:39px;

                &:first-child, &:last-child {
                    margin-top: 0;
                    float: none;
                }
            }
        }
    }

    &__divider {
        @extend %block;
        height: 1px;
        background: #d1d1d1;
        margin-top: 9px;
    }

    &-product {
        @extend %block;

        &__image,
        &__info {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
        }

        &__image {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 112px;
            height: 112px;
            background: #FFFFFF;
            overflow: hidden;
            border: 1px solid #d2d2d2;

            img {
                @extend %block;
                max-width: 95%;
                max-height: 95%;
            }

            &-action {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: 44px;
                height: 44px;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 0;
                font-size: 12px;
                line-height: 18px;
                background:#04aae7;

                .fa {
                    font-size: 20px;
                    line-height: 20px;
                    margin-left: 0;
                }

                &:hover {
                    background:#04aae7;
                }
            }
        }

        &__info {
            width: calc(100% - 114px - 12px);
            margin-left: 12px;
        }
    }

    &-qty {
        @extend %block;
        width: calc(100% - 2px);
        height: 36px;
        border: 1px solid #d1d1d1;

        &__input {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            appearance: none;
            width: 50%;
            height: 36px;
            background: #FFFFFF;
            text-align: center;
            color: #7c7c7c;
            font-size: 17px;
            line-height: 36px;
            @include montserrat();

            &--clear {
                background: transparent;
            }
        }

        &__button {
            width: 50%;
            padding-left: 0;
            padding-right: 0;
            height: 36px;
            line-height: 36px;
            display: inline-block;
            vertical-align: middle;
            background:#04aae7;

            &:hover {
                background:#04aae7;
            }
        }
    }

    &__cell {
        @extend %block;
        display: flex;
        flex-direction: column;
        width: calc(100% - 36px);
        margin: 3px;
        background: #f0f0f0;
        flex-grow: 1;
        padding: 15px;
        justify-content: center;

        p {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            font: 20px/1 $title-font;

            a {
                color: $blue;
                text-decoration: none;
                transition: color 0.5s ease;

                &:hover,
                &:active {
                    color: $blue-hover;
                    text-decoration: none;
                }
            }

            &.info {
                color: #7c7c7c;
                font-size: 13px;
                line-height: 18px;
                text-transform: none;
                @include montserrat();
            }

            @media (max-width: 1000px) {
                text-align: center;
            }
        }

        &--transparent,
        &--code {
            background: transparent;
        }

        &--code {
            padding-left: 0;
            padding-bottom: 5px;
            align-items: flex-end;

            @media (max-width: 1000px) {
                padding-left: 15px;
            }
        }

        @media (max-width: 1000px) {
            margin: 0 !important;
            width: calc(100% - 30px) !important;
            text-align: center;
        }
    }

    &__heading {
        @extend %block;
        width: calc(100% - 40px);
        background: #d4d4d4;
        flex-grow: 1;
        padding: 13px 20px;
        color: #5c5c5c;
        text-align: center;
        text-transform: uppercase;
        font: 20px/1 $title-font;

        &--align-left {
            text-align: left;
        }

        @media (max-width: 1000px) {
            display: none;
        }
    }

    &__row {
        @extend %block;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-shrink: 0;

        &--first {
            margin-top: 3px;

            @media (max-width: 1000px) {
                margin-top: 0 !important;
            }
        }

        &--headings {
            @media (max-width: 1000px) {
                display: none;
            }
        }

        @media (max-width: 1000px) {
            flex-direction: column;
            margin-top: 15px;
        }
    }

    &__col {
        @extend %block;
        width: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;

        &--quantity {
            max-width: 180px;
        }

        &--price {
            max-width: 115px;
        }

        &--actions {
            max-width: 60px;
        }

        &--price-actions {
            max-width: 175px;
        }

        &:first-child {
            .basket__cell {
                width: calc(100% - 33px);
                margin-left: 0;
            }
        }

        &:last-child {
            .basket__cell {
                width: calc(100% - 33px);
                margin-right: 0;
            }
        }

        &--full {
            .basket__cell {
                width: calc(100% - 30px) !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }

        @media (max-width: 1000px) {
            width: 100% !important;
            max-width: 100% !important;
            margin: 0 !important;
        }
    }
    
    &-header {
        @extend %block;
        border: 1px solid #dedede;
        width: calc(100% - 52px);
        background:$dark-blue;
        height: 58px;
        padding: 0 25px;
        color: #FFFFFF;
        text-transform: uppercase;
        overflow: hidden;
        text-align: left;
        transition: text-align 0.5s ease;
        // font-family: 'Dosis', sans-serif;
        // font-size: 20px;
        // line-height: 58px;
        font: 20px/58px $title-font;

        img {
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            transition: top 0.5s ease,
                opacity 0.5s ease;

            @include breakpoint(0, 712px) {
                top: -100px;
                opacity: 0;
            }
        }

        @include breakpoint(0, 712px) {
            text-align: center;
        }
    }

    &-container {
        @extend %block;
        padding-top: 25px;
        padding-bottom: 123px;
        transition: padding-bottom 0.5s ease;

        @include breakpoint(0, 660px) {
            padding-bottom: 25px;
        }
    }
}
