/**
 * Landing Galleries.
 */

.landing-gallery {
    @extend %block;

    &__inner {
        @extend %block;
        overflow: hidden;

        &-placeholder {
            @extend %block;
            white-space: nowrap;
            width: 200000px;
            z-index: -1;
            opacity: 0;
        }

        &-slides {
            @extend %block;
            width: 200000px;
            white-space: nowrap;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: left 0.5s ease;
        }

        &-nav {
            @extend %block;
            position: absolute;
            text-align: center;
            height: 18px;
            bottom: 27px;
            left: 0;

            &__item {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                width: 18px;
                height: 18px;
                background: #FFFFFF;
                cursor: pointer;
                border-radius: 50%;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
                margin-left: 9px;

                &:first-child {
                    margin-left: 0;
                }

                &:hover,
                &:active,
                &--active {
                    background: $green;
                    cursor: pointer;
                }
            }
        }
    }

    &-info {
        @extend %block;
        width: calc(100% - 40px);
        background: $blue;
        padding: 20px;
    }

    &__title,
    &__text {
        @extend %block;
        color: #FFFFFF;
    }

    &__title {
        text-transform: uppercase;
        font: 20px/1 $title-font;
    }

    &__text {
        font-size: 13px;
        line-height: 13px;
        margin-top: 3px;
    }

    &--invert {
        .landing-gallery-info {
            background: $green !important;
        }

        .landing-gallery__inner-nav__item--active,
        .landing-gallery__inner-nav__item:hover,
        .landing-gallery__inner-nav__item:active {
            background: $blue;
        }
    }

    &__slick-slides {
        a {
            text-decoration: none;
        }
        
        &-item {
            padding: 0 12px;
            box-sizing: border-box;

            @media screen and (max-width:797px) {
                padding: 0;
            }

            img {
                width: 100%;
                height: 394px;
            }

            &--body {
                padding: 13px 21px;
                font: 100 13px/16px $body-font;
                color: #fff;
                display: flex;
                flex-direction: column;

                &-title {
                    font: 700 20px/24px $title-font;
                    text-transform: uppercase;
                }
            }

            &:nth-child(odd) &--body {
                background-color: #ec1e30;
            }
            &:nth-child(even) &--body {
                background-color: #1a3563;
            }
        }

        &-nav-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 10px 0;
            // position: absolute;
            // transform: translateX(-50%);
            // left: 50%;
            // bottom: -53px;

            // @media screen and (max-width:797px) {
            //     bottom: -30px;
            // }

            li {
                margin: 0 5px;

                button {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    border: 0;
                    font-size: 0;
                    line-height: 0;
                    background-color: #1a3563;
                    display: block;
                    cursor: pointer;
                }

                &.slick-active button {
                    background-color: #ec1e30;
                }
            }
        }
    }
}
