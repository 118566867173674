.landing-sections {
    >.row {
        // margin-left: 0;
        // margin-right: 0;
        justify-content: space-between;
        margin: 35px 0;
    }

    &__box {
        margin: 0 0 17px;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: cover;

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 17px;
        }

        @media screen and (max-width:797px) {
            margin: 0 0 5px;

            &:nth-child(1),
            &:nth-child(2) {
                margin-top: 5px;
            }
        }

        &-image {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
        }

        &-border {
            position: relative;
            padding: 35px;

            @media screen and (max-width:797px) {
                padding: 35px 20px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                border: 1px solid white;
                width: calc(100% - 30px);
                height: calc(100% - 30px);
            }
        }

        &background {
            background-color: #fff;
        }

        &__content {
            // padding: 20px 34px;
            line-height: 20px;
            font: .9rem/1 $body-font;
            color: white;

            &-title,
            p {
                strong {
                    font-family: 'Montserrat Bold', sans-serif;
                    font-size: 15px;
                }
            }

            &-title {
                text-transform: uppercase;
                font: 700 38px/1 $title-font;
                color: $red;
                text-shadow: 4px 2px 5px rgba(0, 0, 0, 0.7);

                &::first-line {
                    color: white;
                }
            }

            p {
                font-family: $body-font;
                font-weight: 100;
                font-size: 13px;
                line-height: 19px;
                // background-color: #fff;
                display: inline;
            }

            p:before {
                padding-top: 9px;
                content: '';
                display: block;
            }

            p:after {
                padding-bottom: 9px;
                content: '';
                display: block;
            }

            // a {
            //     margin-right: 7px;
            //     width: auto;
            //     display: inline-block;
            // }
        }
    }

    @media only screen and (min-width: 48em) {
        .col-sm-6 {
            flex-basis: 49%;
            max-width: 49%;
        }
    }

    .img-responsive {
        width: 100%;
        height: auto;
        display: block;
    }

    @media screen and (max-width: 797px) {
        padding: 0 !important;
        max-width: calc(100% - 15px);

        >.row {
            margin: 20px 0;

            >.col-xs-12 {
                padding: 0;
            }
        }
    }
}