/**
 * Category Intro.
 */

.category {
    &-filter {
        @extend %reset;
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        padding: 20px 0;

        &__order {
            @extend %reset;
            width: 201px;
            height: 34px;
            border: 1px solid #c9c9c9;
            line-height: 34px;
            font-size: 13px;
            color: #7c7c7c;
            padding-left: 15px;
            appearance: none;
            background: url('/assets/images/select.jpg') no-repeat center right #FFFFFF;
            margin-left: auto;
            @include montserrat();
        }

        &__colour {
            @extend %reset;
            display: inline-block;
            vertical-align: top;
            width: 15px;
            height: 15px;
            margin-left: 3px;
            border: 1px solid $blue;
        }

        &__title {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            cursor: pointer;
            text-decoration: none;
            transition: color 0.5s ease;
            font: 17px/1 $title-font;

            span {
                color: $green;
            }

            i {
                margin-left: 7px;

                &.no-icon {
                    font-size: 12px;
                    line-height: 12px;
                    vertical-align: middle;
                    @include montserrat();
                }
            }

            &:hover,
            &:active {
                color: $blue-hover;
            }
        }

        &-colours {
            @extend %block;
            margin-top: -4px;
            margin-left: -4px;

            &__item {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                margin-left: 4px;
                margin-top: 4px;
                width: 32px;
                height: 32px;
                border: 1px solid #e0e0e0;
                transition: border-color 0.5s ease;

                &:hover,
                &:active {
                    cursor: pointer;
                    border-color: $blue;
                }
            }
        }

        &-list {
            @extend %block;

            &__item,
            &__item a {
                @extend %block;
                color: $blue;
                font-size: 13px;
                line-height: 13px;
                text-decoration: none;
                transition: color 0.5s ease;
                @include montserrat();

                &:hover,
                &:active {
                    color: $blue-hover;
                    text-decoration: underline;
                }
            }

            &__item {
                margin-top: 5px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        &-overlay,
        &-overlay:before {
            @extend %block;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.45);
            position: absolute;
            background: #FFFFFF;
        }

        &-overlay {
            width: 260px;
            top: 100%;
            left: 50%;
            margin-left: -130px;
            z-index: 5;
            display: none;

            &__inner {
                @extend %block;
                width: calc(100% - 30px);
                padding: 15px;
                background: #FFFFFF;
                max-height: 250px;
                overflow-y: auto;
            }

            &:before {
                width: 20px;
                height: 20px;
                top: -10px;
                left: 50%;
                margin-left: -10px;
                content: '';
                transform: rotate(45deg);
            }

            &--visible {
                display: block;
            }
        }

        &-container {
            @extend %block;

            &__inner {
                @extend %block;
                display: flex;
                flex-direction: row;
                border: 1px solid #cfcfcf;
                border-left: none;
                border-right: none;
                align-items: center;
                min-height: 57px;

                &--scroll {
                    width: calc(100% - 40px);
                    position: fixed;
                    top: -1px;
                    left: 0;
                    background: #FFFFFF;
                    padding: 0 20px;
                    z-index: 5;
                }
            }
        }

        &:first-child {
            margin-left: 0;
        }

        &--first {
            .category-filter-overlay {
                left: 0;
                margin-left: 0;
            }
        }
    }

    &-intro {
        @extend %block;
        padding: 30px 0;
        text-align: center;

        &__header {
            @extend %block;
            font: 700 24px/1 $title-font;
            text-transform: uppercase;
            color: $dark-blue;
        }

        &__more {
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.5s ease;

            &--open {
                max-height: 500px;
            }
        }

        p {
            @extend %block;
            margin-top: 12px;
            font-size: 13px;
            line-height: 19px;
            color: #7c7c7c;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
            @include montserrat();

            a:not(.button) {
                color: #7c7c7c;
                text-decoration: underline;
                transition: color 0.5s ease;

                &:hover,
                &:active {
                    color: $blue;
                }
            }

            a:not(.no-format) {
                text-transform: uppercase;
            }

            &.big {
                font-size: 15px;
                color: $green;

                a:not(.button) {
                    color: $blue;
                }
            }
        }
    }
}