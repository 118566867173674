/**
 * Content Pages.
 */

.page {
    @extend %block;
    /*background: url('/assets/images/page-background.jpg') no-repeat top center transparent;*/
    background:#f9f9fb;
    padding: 75px 0;

    &-sidebar {
        @extend %block;

        a {
            @extend %block;
            margin-top: 25px;

            img {
                @extend %block;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &-content {
        @extend %block;
        background: #FFFFFF;
        padding: 35px;
        width: calc(100% - 70px);

        &__header {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            margin-bottom: 30px;
            font: 20px/1 $title-font;
        }

        img {
            @extend %reset;
            max-width: 100%;
            max-height: 100%;
            height: auto;
            width: auto;
        }

        strong {
            font-weight: bold;
        }

        em {
            font-style: italic;
        }

        h1:not(.page-content__header),
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ol li,
        ul li {
            @extend %block;
            font-size: 13px;
            line-height: 19px;
            color: #7c7c7c;
        }

        ol,
        ul {
            padding-left: 15px;
        }

        ol li,
        ul li {
            list-style: disc;
            display: list-item;
        }

        ol li {
            list-style: decimal;
        }

        h1:not(.page-content__header),
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $blue;
            font-weight: bold;
        }
    }
}
