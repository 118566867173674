/**
 * Gallery Slider.
 */

.gallery-slider {
    @extend %block;
    margin: 25px 0;

    &-slides {
        @extend %block;

        &__slide {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            width: 120px;
            height: 120px;
            background-position: center center;
            background-size: auto 100%;
            margin-left: 4px;
            margin-top: 4px;

            &:first-child {
                margin-left: 0;
            }

            &:hover,
            &:active,
            &--active {
                box-shadow: inset 0 0 0 3px $green;
                cursor: pointer;
            }
        }
    }

    &__slide {
        @extend %block;

        img {
            @extend %block;
        }
    }
}
