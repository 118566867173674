/**
 * Black Friday Popup.
 */

.bf {
    @extend %block;
    flex-direction: column;
    display: flex;
    flex-align: center;
    justify-content: center;
    max-width: 457px;
    max-height: 457px;
    height: auto;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    z-index: 100000001;
    margin: auto;

    &__image {
        @extend %block;
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &-mask {
        @extend %block;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.46);
        z-index: 100000000;
    }

    @include breakpoint(0, 490px) {
        width: calc(100% - 20px);
        max-height: calc(100% - 20px);
    }
}
