/**
 * Quote System.
 */

.quote {
    &-container {
        @extend %block;
        background: #edf6f9;
        padding: 40px 0;
    }

    &-form {
        @extend %block;
        width: calc(100% - 52px);
        border: 1px solid #ced6d9;
        background: #FFFFFF;
        margin-top: 25px;
        padding: 25px;

        &-input {
            @extend %block;
            margin-top: 12px;

            &__label {
                @extend %block;
                font-size: 13px;
                line-height: 13px;
                color: #7c7c7c;
                @include montserrat();
            }

            &__input {
                @extend %block;
                width: calc(100% - 32px);
                border: 1px solid #c9c9c9;
                height: 36px;
                line-height: 36px;
                color: #7c7c7c;
                font-size: 13px;
                -webkit-appearance: none;
                border-radius: 0;
                padding: 0 15px;
                margin-top: 4px;

                &--textarea {
                    height: 150px;
                    line-height: 18px;
                    padding: 10px 15px;
                }
            }
        }

        &__header {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            font: 20px/1 $title-font;
        }
    }

    &-final {
        @extend %block;
        width: calc(100% - 82px);
        border: 1px solid #ced6d9;
        background: #FFFFFF;
        margin-top: 25px;
        padding: 40px;

        &__divider {
            @extend %block;
            height: 1px;
            background: #c5c6c6;
        }

        &__header {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            font: 21px/1 $title-font;
        }

        &__price {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            // font-family: 'Dosis', sans-serif;
            // font-size: 60px;
            // line-height: 35px;
            font: 60px/35px $title-font;

            small {
                font-size: 22px;
            }
        }

        &__actions {
            @extend %block;
            display: flex;
            flex-direction: row;

            .button {
                margin-left: 10px;
                flex-grow: 1;
                flex-shrink: 1;

                &:first-child {
                    margin-left: 0;
                }

                @include breakpoint(0, 1180px) {
                    margin-left: 0;
                    margin-top: 10px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            &--single {
                display: block;
            }

            @include breakpoint(0, 1180px) {
                flex-direction: column;
            }
        }

        &__p {
            @extend %block;
            font-size: 13px;
            line-height: 20px;
            color: #7c7c7c;
            @include montserrat();

            span {
                color: #cb4646;
            }

            a {
                color: #1d90b5;
                text-decoration: underline;
                transition: color 0.5s ease;
                @include montserrat('Bold');

                &:hover,
                &:active {
                    color: $green;
                    text-decoration: underline;
                }
            }
        }

        &__upload {
            @extend %block;
            display: flex;
            flex-direction: row;
            border: 1px solid #c9c9c9;

            &-label {
                flex-shrink: 0;
                flex-grow: 0;
            }

            &-hint {
                @extend %block;
                display: flex;
                // font-size: 14px;
                flex-grow: 1;
                align-items: center;
                padding: 0 15px;
                font-size: 13px;
                line-height: 20px;
                color: #7c7c7c;
                @include montserrat();
            }

            input {
                position: fixed;
                top: -100000px;
                left: -100000px;
                opacity: 0;
            }
        }

        &__divider,
        &__header,
        &__price,
        &__actions,
        &__p,
        &__upload {
            margin-top: 15px;

            &:first-child {
                margin-top: 0;
            }
        }

        &__quantity {
            @extend %block;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background: #FFFFFF;
            width: 92px;
            height: 92px;
            border-radius: 50%;
            text-transform: uppercase;
            color: $blue;
            // font-size: 21px;
            // line-height: 21px;
            // font-family: 'Dosis', sans-serif;
            font: 21px/1 $title-font;
        }

        &__balloon,
        &__content {
            @extend %reset;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            vertical-align: middle;

            @include breakpoint(0, 900px) {
                display: flex;
            }
        }

        &__balloon {
            width: 25%;

            img {
                @extend %block;
            }

            @include breakpoint(0, 900px) {
                width: 100%;
            }
        }

        &__content {
            width: calc(75% - 40px);
            padding-left: 40px;

            @include breakpoint(0, 900px) {
                width: 100%;
                padding-left: 0;
                margin-top: 40px;
            }
        }
    }

    &-products {
        @extend %block;

        &__item {
            @extend %block;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            width: calc(100% - 42px);
            border: 1px solid #ced6d9;
            background: #FFFFFF;
            margin-top: 25px;
            padding: 20px;

            &-variation {
                @extend %block;
                height: 36px;
                line-height: 36px;
                border: 1px solid #C9C9C9;
                font-size: 13px;
                color: #7c7c7c;
                background: url('/assets/images/select.jpg') no-repeat center right #FFFFFF;
                margin-top: 10px;
                border-radius: 0;
                -webkit-appearance: none;
                padding: 0 15px;
                @include montserrat();
            }

            &-quantity {
                @extend %block;
                margin-top: 10px;
                text-align: center;

                label,
                input {
                    @extend %reset;
                    display: inline-block;
                    vertical-align: middle;
                }

                label {
                    margin-right: 6px;
                    color: $blue;
                    text-transform: uppercase;
                    font: 20px/1 $title-font;
                }

                input {
                    width: 54px;
                    height: 34px;
                    line-height: 34px;
                    border: 1px solid #c7c7c7;
                    text-align: center;
                    color: #7c7c7c;
                    font-size: 22px;
                    @include montserrat('Bold');
                }
            }

            &-divider {
                @extend %block;
                height: 1px;
                background: #c5c5c5;
                margin-top: 10px;
            }

            &-title {
                @extend %block;
                text-align: center;
                font-size: 13px;
                line-height: 18px;
                color: #7c7c7c;
                margin-top: 10px;
                @include montserrat();
            }

            &-price {
                @extend %block;
                text-align: center;
                font-size: 19px;
                line-height: 19px;
                color: $blue;
                margin-top: 10px;
                @include montserrat('Bold');
            }

            &-count {
                @extend %block;
                display: flex;
                width: 58px;
                height: 58px;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 50%;
                background: $blue;
                position: absolute;
                top: 0;
                left: 0;
                color: #FFFFFF;
                z-index: 1;
                font: 20px/1 $title-font;
            }

            &-image {
                @extend %block;
                display: flex;
                flex-grow: 1;
                align-items: center;
                justify-content: center;
                text-align: center;

                img {
                    @extend %reset;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }

    &-errors {
        @extend %block;
        width: calc(100% - 42px);
        border: 1px solid #ced6d9;
        background: #FFFFFF;
        margin-top: 25px;
        padding: 20px;

        &__item {
            @extend %block;
            color: #FF0000;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            @include montserrat();
        }
    }

    &-actions {
        @extend %block;
        width: calc(100% - 22px);
        border: 1px solid #ced6d9;
        background: #FFFFFF;
        margin-top: 25px;
        padding: 10px;

        @include breakpoint(0, 768px) {
            .button {
                display: block;
                width: 100%;
                text-align: center;
                padding: 0;
            }

            .col-xs-12:last-child .button {
                margin-top: 10px;
            }
        }
    }

    &-input {
        @extend %block;
        margin-top: 12px;

        &-flex {
            @extend %block;
            display: flex;
            flex-direction: row;

            &__shrink {
                display: flex;
                flex-shrink: 1;
                flex-grow: 1;
            }

            &__main {
                @extend %reset;
                display: inline-block;
                flex-direction: row;
                flex-shrink: 0;
                flex-grow: 0;
                padding-left: 15px;

                .button {
                    height: 35px;
                    line-height: 35px;
                }
            }
        }

        &__label {
            @extend %block;
            font-size: 13px;
            line-height: 13px;
            color: #7c7c7c;
            margin-bottom: 5px;
            @include montserrat();
        }

        &__select {
            @extend %block;
            border: 1px solid #c9c9c9;
            font-size: 15px;
            line-height: 35px;
            height: 35px;
            padding: 0 15px;
            color: #7c7c7c;
            background: url('/assets/images/select.jpg') no-repeat center right #FFFFFF;
            -webkit-appearance: none;
            border-radius: 0;
            @include montserrat();
        }

        &__text {
            @extend %block;
            width: calc(100% - 30px);
            border: 1px solid #c9c9c9;
            font-size: 15px;
            line-height: 35px;
            height: 35px;
            padding: 0 15px;
            color: #7c7c7c;
            -webkit-appearance: none;
            border-radius: 0;
            @include montserrat();
        }
    }

    &-printing {
        @extend %block;
        width: calc(100% - 2px);
        border: 1px solid #ced6d9;
        background: #FFFFFF;
        margin-top: 25px;

        &__header {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            font: 20px/1 $title-font;
        }

        &__image {
            @extend %block;
            width: calc(100% - 50px);
            padding: 25px;
            text-align: center;

            img {
                @extend %reset;
                max-width: 100%;
            }
        }

        &__form {
            @extend %block;
            width: calc(100% - 25px);
            padding: 25px;
            padding-left: 0;

            @include breakpoint(0, 991px) {
                padding-left: 25px;
                width: calc(100% - 50px);
            }
        }
    }

    &-list {
        @extend %block;

        &__item {
            @extend %block;
            width: calc(100% - 2px);
            border: 1px solid #ced6d9;
            background: #FFFFFF;
            margin-top: 25px;
            overflow: hidden;

            &-title {
                @extend %block;
                color: $blue;
                text-transform: uppercase;
                font: 20px/1 $title-font;
            }

            &-image,
            &-content {
                @extend %reset;
                width: 100%;
                display: inline-block;
                vertical-align: middle;
            }

            &-image {
                max-width: calc(35% - 20px);
                padding-left: 20px;
                text-align: center;
                overflow: hidden;

                img {
                    @extend %reset;
                    max-width: 100%;
                }

                @include breakpoint(0, 600px) {
                    max-width: 0;
                }
            }

            &-content {
                max-width: calc(65% - 40px);
                padding: 35px 20px;

                p {
                    @extend %block;
                    font-size: 13px;
                    color: #7c7c7c;
                    line-height: 19px;
                    margin-top: 5px;
                    @include montserrat();
                }

                .button {
                    margin-top: 15px;
                }

                @include breakpoint(0, 600px) {
                    max-width: calc(100% - 40px);
                    padding: 20px;
                    text-align: center;
                }
            }
        }
    }

    &-sidebar {
        @extend %block;
        width: calc(100% - 2px);
        border: 1px solid #ced6d9;
        background: #FFFFFF;

        &__header {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            margin-bottom: 6px;
            font: 18px/1 $title-font;
        }

        &__summary {
            @extend %block;
            font-size: 13px;
            line-height: 19px;
            color: #7c7c7c;
            margin-top: 3px;
            @include montserrat();

            span {
                color: $blue;
                @include montserrat('Bold');
            }
        }

        &-steps {
            @extend %block;

            &__step {
                @extend %block;
                padding: 10px;
                width: calc(100% - 20px);
                background: #d6ebf1;

                &-number,
                &-text {
                    @extend %reset;
                    display: inline-block;
                    vertical-align: middle;
                }

                &-number {
                    width: 30px;
                    height: 30px;
                    background: #FFFFFF;
                    text-align: center;
                    color: $green;
                    border-radius: 50%;
                    // font-family: 'Dosis', sans-serif;
                    // line-height: 30px;
                    // font-size: 18px;
                    font: 18px/30px $title-font;
                }

                &-text {
                    margin-left: 8px;
                    max-width: calc(100% - 30px - 8px);
                    color: $blue;
                    text-transform: uppercase;
                    font: 18px/1 $title-font;
                }

                &:nth-child(2n+1) {
                    background: #b3dae5;
                }

                &--active {
                    background: $blue !important;

                    .quote-sidebar-steps__step-text {
                        color: #FFFFFF;
                    }
                }
            }
        }

        &__inner {
            @extend %block;
            padding: 15px;
            width: calc(100% - 30px);
        }
    }

    &__header {
        @extend %block;
        width: calc(100% - 22px);
        border: 1px solid #ced6d9;
        background: #FFFFFF;
        padding: 20px 10px;
        text-transform: uppercase;
        color: $blue;
        text-align: center;
        font: 20px/1 $title-font;

        &:after {
            @extend %block;
            width: 31px;
            height: 17px;
            left: 50%;
            margin-left: -15px;
            bottom: -17px;
            background: url('/assets/images/quote/triangle.png') no-repeat center transparent;
            content: '';
            position: absolute;
            z-index: 1;
        }

        &-sub {
            @extend %block;
            font-size: 13px;
            line-height: 18px;
            color: #7c7c7c;
            text-transform: none;
            overflow: hidden;
            max-height: 25px;
            white-space: nowrap;
            transition: max-height 0.5s ease,
                opacity 0.5s ease;
            @include montserrat();

            @include breakpoint(0, 610px) {
                text-align: left;
            }

            @include breakpoint(0, 480px) {
                max-height: 0;
                opacity: 0;
            }
        }

        &-action {
            position: absolute;
            right: 9px;
            top: 9px;

            @include breakpoint(0, 350px) {
                display: block;
                position: relative;
                top: auto;
                right: auto;
                margin-top: 5px;
                width: 100%;
                text-align: center;
                padding-left: 0;
                padding-right: 0;
            }
        }

        &--fixed {
            height: 58px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0;
            padding-bottom: 0;
            flex-direction: column;

            @include breakpoint(0, 610px) {
                align-items: flex-start;
            }
        }

        @include breakpoint(0, 768px) {
            margin-top: 25px;
        }

        @include breakpoint(0, 350px) {
            height: 90px;
        }
    }
}
