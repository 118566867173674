/**
 * Social Media Elements.
 */

.social {
    text-align: center;

    &__item {
        @extend %reset;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        margin: 0 10px;

        a {
            @extend %block;
            line-height: $social-size-small;
            color: $dark-blue;
            font-size: 22px;

            @include transition(all 0.5s ease);
        }

        &:first-child {
            margin-left: 0;
        }
    }

    &--inline {
        width: auto;
        display: inline-block;
        vertical-align: middle;
    }

    &--spacing {
        background: #FFFFFF;
        padding: 0 5px;
    }

    &--large {
        .social__item {
            width: $social-size-large;
            height: $social-size-large;

            a {
                background: #e1eff6;
                width: $social-size-large;
                height: $social-size-large;
                line-height: $social-size-large;
                font-size: 28px;

                &:hover,
                &:active {
                    background: #c8d9e2;
                }
            }
        }
    }
}