/**
 * Contact Page.
 */

.contact {
    @extend %block;
    padding-top: 40px;
    padding-bottom: 450px;
    transition: padding-bottom 0.5s ease;

    .container .row .col-xs-12:nth-child(2) {
        display: flex;
    }

    &-form {
        @extend %block;
        margin-top: 30px;

        &__error {
            color: #FF0000 !important;
        }

        &__success {
            color: #22a522 !important;
        }

        &__button {
            margin-top: 20px;
        }

        &-input {
            @extend %block;
            margin-top: 10px;

            &__label {
                @extend %block;
                font-size: 13px;
                line-height: 13px;
                color: #7c7c7c;
                @include montserrat();

                span {
                    color: $red;
                    margin-left: 3px;
                    font-size: 15px;
                }
            }

            &__input,
            &__textarea {
                @extend %block;
                width: calc(100% - 22px);
                border: 1px solid #c9c9c9;
                height: 36px;
                line-height: 36px;
                font-size: 13px;
                padding: 0 10px;
                color: #7c7c7c;
                margin-top: 5px;
            }

            &__textarea {
                line-height: 18px;
                padding: 10px;
                height: 100px;
                resize: none;
            }
        }
    }

    &-map {
        @extend %block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 620px;
        background: #F0F0F0;
        z-index: -1;
        transition: opacity 0.5s ease;

        &:before {
            @extend %block;
            height: 6px;
            background: rgba(0, 0, 0, 0.05);
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
        }

        #map {
            @extend %block;
            height: 100%;
            z-index: -1;
        }

        @include breakpoint(0, 1100px) {
            opacity: 0;
        }
    }

    &-list {
        @extend %block;
        margin-bottom: 20px;

        &__item {
            @extend %block;
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

            &:not(:last-of-type) {
                align-items: center;
            }

            &-icon,
            &-text {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
            }

            &-icon {
                font-size: 32px;
                color: $blue;
            }

            &-text {
                width: calc(100% - 5px - 28px);
                margin-left: 5px;

                .large {
                    color: $blue;
                    font-size: 16px;
                    @include montserrat('Bold');
                }

                .blue {
                    color: $blue;
                    @include montserrat('Bold');
                }

                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &--top {
                .contact-list__item-icon,
                .contact-list__item-text {
                    vertical-align: top;
                }
            }
        }
    }

    &-box {
        @extend %block;
        width: calc(100% - 52px);
        border: 1px solid #d1cec7;
        padding: 25px;
        background: #FFFFFF;
        transition: margin-top 0.5s ease;

        &__header {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            font: 20px/1 $title-font;
        }

        p {
            @extend %block;
            font-size: 13px;
            line-height: 19px;
            color: #7c7c7c;
            margin-top: 10px;
            @include montserrat();

            &:first-child {
                margin-top: 0;
            }
        }

        @include breakpoint(0, 1100px) {
            margin-top: 20px;

            &--no-margin {
                margin-top: 0;
            }
        }
    }

    @include breakpoint(0, 1100px) {
        padding-bottom: 40px;
    }
}
