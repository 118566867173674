/**
 * Product Page.
 */

.upsell {
    @extend %reset;
    background: #FFFFFF;
    border: 1px solid #dadada;
    max-width: 100%;
    max-height: 100%;

    .button {
        background:$dark-blue;
        max-width:100%!important;

        &.disabled {
            opacity:0.4;
            background:$red;
        }
    }

    #close-btn {
        .button {
            display:inline!important;
        }
    }

    &__header {
        @extend %block;
        background: $blue;
        text-align: center;
        height: 55px;
        text-transform: uppercase;
        color: #FFFFFF;
        font: 23px/55px $title-font;
    }

    &__inner {
        @extend %block;
        padding: 25px 0;
    }

    &-slider {
        @extend %block;
        width: calc(100% - 140px);
        padding: 0 70px;
        margin-bottom: 25px;

        &__slide {
            @extend %reset;
            display: inline-flex;
            flex-direction: column;
            width: 231px;
            max-width: 231px;
            border: 1px solid #e0e0e0;
            padding: 15px;
            flex-grow: 1;
            flex-shrink: 0;
            margin-left: 30px;
            white-space: normal;

            .button {
                margin-top: 20px;
            }

            &-title {
                @extend %block;
                font-size: 13px;
                line-height: 19px;
                color: #7c7c7c;
                margin-top: 20px;
                text-align: center;
                @include montserrat();
            }

            &-price {
                @extend %block;
                color: $blue;
                font-size: 20px;
                line-height: 20px;
                margin-top: 10px;
                font-weight: normal;
                text-align: center;
                @include montserrat('Bold');
            }

            &-inner {
                @extend %block;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }

            &-image {
                @extend %block;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 1;

                img {
                    @extend %block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            &:first-child {
                margin-left: 0;
            }
        }

        &__container {
            @extend %block;
            overflow: hidden;
            white-space: nowrap;
            margin: 0 auto;
        }

        &__inner {
            @extend %block;
            display: flex;
            flex-flow: row;
            position: absolute;
            top: 0;
            left: 0;
            white-space: nowrap;
            transition: left 0.25s ease;

            &--placeholder {
                position: relative;
                z-index: -1;
                opacity: 0;
                top: auto;
                left: auto;
            }
        }

        &__nav {
            @extend %block;
            display: flex;
            width: 45px;
            height: 45px;
            align-items: center;
            justify-content: center;
            background:$red;
            position: absolute;
            top: 50%;
            margin-top: -22.5px;
            transition: background 0.5s ease;

            &:hover,
            &:active {
                cursor: pointer;
                background: $red-hover;
            }

            .fa {
                color: #FFFFFF;
                font-size: 31px;
                line-height: 31px;
            }

            &--left {
                left: 10px;
            }

            &--right {
                right: 10px;
            }
        }
    }

    &-mask {
        @extend %block;
        display: none;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1000;

        &--visible {
            display: flex;
        }
    }

    @include breakpoint(0, 800px) {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        border: none;
    }
}

.pricing-table {
    width: 100%;
    border: 1px solid #e0e0e0;

    tr,
    td {
        font: 300 15px/1 'Montserrat';

        @media (max-width:991px) {
            font-size:13px;
        }
    }

    td {
        border: 1px solid #e0e0e0;
    }

    tr {
        &:nth-child(odd) {
            background: #e8e7e8;

            td {
                background: #e8e7e8;
            }
        }
    }

    td {
        padding: 6px 11px;
    }

    .price {
        color: $red;
        font-weight: 700;
    }
}

.product-page {
    @extend %block;
    padding: 25px 0;

    &-variations {
        @extend %block;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #CFCFCF;

        &__label {
            @extend %block;
            font-size: 18px;
            color: $dark-blue;
            @include montserrat('Bold');
            margin: 0 0 10px 0;

            span {
                color:$red;
            }
            
            @media (max-width:992px) {
                font-size: 16px;

                &.qty-discount {
                    margin:10px 0;
                }
            }
        }

        &__option {
            @extend %block;
            margin-top: 5px;
            color: #7c7c7c;
            font-size: 13px;
            line-height: 18px;
            @include montserrat();
        }

        select {
            padding: 5px;
        }
    }

    &-social {
        @extend %reset;
        position: absolute;
        top: 0;
        right: 0;

        &__item {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            width: 39px;
            height: 39px;
            background: #b2b2b2;
            text-align: center;
            line-height: 39px;
            margin-left: 12px;
            transition: background 0.5s ease;

            &-overlay {
                @extend %block;
                width: 100px;
                height: 30px;
                line-height: 30px;
                padding: 5px;
                border: 1px solid #999999;
                background: #FFFFFF;
                text-align: center;
                z-index: 10;
                position: absolute;
                left: 50%;
                margin-left: -50px;
                top: -32px;
                display: none;
            }

            i {
                color: #FFFFFF;
                font-size: 20px;
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover,
            &:active {
                background: $blue;

                .product-page-social__item-overlay {
                    display: block;
                }
            }
        }

        &--self {
            position: relative;
            top: auto;
            right: auto;
        }

        @include breakpoint(0, 840px) {
            position: relative;
            right: auto;
            top: auto;
            margin-top: 10px;
        }
    }

    &-actions {
        @extend %block;
        margin-top: 10px;
    }

    &-images {
        @extend %block;
        width: calc(100% - 20px);
        padding: 10px;
        text-align:center;

        &__image {
            @extend %block;
            display: none;

            img {
                @extend %block;
            }
        }

        &--variation {
            display: none;
        }
    }

    &-slider-nav {
        @extend %block;
        margin-top: 16px;
        text-align: center;

        &__item {
            @extend %reset;
            width: calc(25% - 8px - (48px / 4));
            display: inline-block;
            vertical-align: middle;
            border: 1px solid #d8d8d8;
            padding: 3px;
            margin-left: 16px;
            text-align: center;
            overflow: hidden;
            transition: border-color 0.5s ease;

            img {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                max-height: 100%;
                max-width: 100%;
                width: auto;
                height: auto;
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover,
            &:active {
                border-color: $blue;
                cursor: pointer;
            }
        }

        &--hidden {
            display: none;
        }

        &--variation {
            display: none;
        }
    }

    &-options {
        @extend %block;
        margin-bottom: 15px;
        border-bottom: 1px solid #C7C7C7;
        padding-bottom: 20px;

        &__location-button {
            @extend %block;
            width: 114px;
            height: 27px;
            background: #7c7c7c;
            color: #FFFFFF;
            line-height: 27px;
            font-size: 12px;
            text-align: center;
            transition: background 0.5s ease;
            position: absolute;
            right: 4px;
            top: 4px;
            z-index: 1;
            @include montserrat();

            i {
                margin-right: 5px;
            }

            &:hover,
            &:active {
                background: $blue;
                cursor: pointer;
            }

            @include breakpoint(0, 460px) {
                display: none;
            }
        }

        &__info {
            @extend %block;
            width: calc(100% - 20px);
            padding: 10px;
            background: #f0f0f0;
            color: #7c7c7c;
            font-size: 13px;
            line-height: 13px;
            @include montserrat();
        }

        &__text,
        &__textarea {
            text-align:center!important;
            @extend %block;
            width: calc(100% - 20px);
            background: #FFFFFF;
            font-size: 14px;
            line-height: 18px;
            color: #7c7c7c;
            padding-right: 122px;
            @include montserrat();

            @media (max-width:460px) {
                padding-left: 10px;
                width: calc(100% - 20px);
            }
        }

        &__text {
            height: 34px;
            line-height: 34px;
            padding: 0 10px;
        }

        &__textarea {
            resize: none;
            height: 60px;
            padding: 10px;
        }

        &__header {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            font: 20px/1 $title-font;
        }

        &__field {
            @extend %block;
            width: calc(100% - 2px);
            border: 1px solid #c7c7c7;
            margin-top: 6px;
        }

        &__label {
            @extend %block;
            font-size: 13px;
            line-height: 13px;
            color: #7c7c7c;
            margin-top: 20px;
            @include montserrat();
        }
    }

    &-buy {
        @extend %block;
        width: calc(100% - 42px);
        margin-top: 20px;
        border: 1px solid #e1e1e1;
        padding: 20px;

        &__inner {
            @extend %block;
            margin-bottom: 15px;

            input,
            label,
            .product-page-buy__price {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                width: auto;
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;
                }
            }

            input {
                border: 1px solid #c7c7c7;
                height: 36px;
                line-height: 36px;
                width: 56px;
                text-align: center;
                color: #7c7c7c;
                font-size: 22px;
                @include montserrat('Bold');
            }

            label {
                text-transform: uppercase;
                color: $dark-blue;
                font: 700 20px/1 $title-font;
            }

            .product-page-buy__price {
                color: $red;
                font-size: 25px;
                line-height: 25px;
                @include montserrat('Bold');
            }
        }
    }

    &-next {
        @extend %block;
        border: 1px solid #CFCFCF;
        border-left: none;
        border-right: none;
        margin-top: 15px;
        padding: 10px 0;

        &__col {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            max-width: calc(100% - 45px);

            p {
                @extend %block;
                font-size: 13px;
                line-height: 13px;
                color: #7c7c7c;
                margin-top: 3px;

                &.bold {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 14px;

                    span {
                        font-size: 21px;
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }

            &--first {
                width: 45px;
                font-size: 36px;
                line-height: 36px;
                color: #7c7c7c;
                margin-right: 15px;
            }
        }
    }
    
    &__back {
        margin-bottom: 25px;
        background:$dark-blue;
        z-index:0;

        &:hover {
            background:$dark-blue-hover;
        }
    }

    &__price {
        @extend %block;
        color: $blue;
        font-size: 15px;
        line-height: 15px;
        margin-top: 15px;
        @include montserrat('Bold');

        span {
            font-size: 25px;
            line-height: 25px;
        }

        &--sale {
            color: #D60000;

            span.out {
                color: $blue;
                text-decoration: line-through;
            }
        }
    }

    &__image {
        @extend %block;
        max-width: calc(100% - 2px);
        border: 1px solid #d8d8d8;
    }
}

.tab-area {
    @extend %block;

    &-tabs {
        @extend %block;
        border-bottom: 1px solid #e0e0e0;

        &__tab {
            @extend %reset;
            display: inline-block;
            vertical-align: bottom;
            border: 1px solid #e0e0e0;
            font-size: 13px;
            line-height: 44px;
            height: 44px;
            padding: 0 15px;
            color: #7c7c7c;
            border-bottom: none;
            background: #FFFFFF;
            margin-left: 14px;
            transition: margin-left 0.5s ease,
                padding 0.5s ease;
            @include montserrat();

            &:first-child {
                margin-left: 0;
            }

            &:hover,
            &:active,
            &.tab-area-tabs__tab--active {
                cursor: pointer;
                margin-bottom: -1px;
                border-bottom: 1px solid #FFFFFF;
            }

            @include breakpoint(0, 390px) {
                margin-left: 5px;
                padding: 0 7px;
            }
        }
    }

    &-content {
        @extend %block;

        &__header {
            @extend %block;
            color: $dark-blue;
            text-transform: uppercase;
            font: 700 23px/1 $title-font;
        }

        p {
            @extend %block;
            color: #7c7c7c;
            margin-top: 10px;
            font: 300 13px/19px 'Montserrat';

            &.small {
                margin-top: 0;
                font-weight: bold;
            }
        }

        &__single {
            @extend %block;
            padding-top: 15px;
            display: none;
        }
    }

    @include breakpoint(0, 768px) {
        margin-top: 20px;
    }
}

.free-logo {
    margin:20px 0 0;

    &-description {
        font:700 14px/1 'Montserrat Bold';
        color:$dark-blue;

        span {
            color:#7b7b7b;
            font-weight:normal;
            display:block;
            margin:5px 0;
            font-family:$body-font;
            font-size:12px;
        }
    }

    input {
        visibility:hidden;
        opacity:0;
        height:0;
        line-height:0;
        font-size:0;
    }

    .upload-logo {
        background:$dark-blue;
    }
    
    #upload-info {
        font:700 13px/1 $body-font;
        background:#04aae7;
        color:#fff;
        display:flex;
        margin:10px 0;
        padding:5px;
        align-items:center;
        justify-content:center;

        &.ready {
            background:$green;
        }

        &.error {
            background:$red;
        }

        @media (min-width:992px) {
            padding:10px;
            font-size:14px;
        }

        i {
            margin:0 10px 0 0;
        }
    }
}

.customise-trophy {
    .customise-text {
        font:13px/1.2 $body-font;
        margin:10px 0;
        color:#7c7c7c;
        padding:0 15px 0 0;

        &.border {
            position:relative;

            &::after {
                position:absolute;
                top:0;
                right:0;
                width:1px;
                height:90px;
                border-right:1px solid #cfcfcf;
                content:' ';

                @media (max-width: 992px) {
                    display:none;
                }
            }
        }
    }

    .button {
        font-weight:700;
    }
}

#saved-templates {
    border-top:1px solid #cfcfcf;
    padding:30px 0 0 0;
    margin:20px 0 0 0;

    .title {
        font:700 23px/1.2 $title-font;
        color:$dark-blue;
        text-align:center;
        text-transform:uppercase;
    }

    .saved-template {
        border:1px solid #e0e0e0;
        height:386px;
        margin:20px 0 0 0;

        .img {
            position:relative;
            height:276px;

            img {
                width:100%;
            }

            .template {
                background:$dark-blue;
                color:#fff;
                font:700 15px/1 $title-font;
                text-transform:uppercase;
                display:inline-block;
                padding:8px 16px;
                position:absolute;
                top:0;
                left:0;

                i {
                    margin:0 0 0 2px;
                }
            }
        }
        
        .name {
            text-align:center;
            font:17px/1.2 'Montserrat Bold';
            color:$dark-blue;
            padding:15px;

            .button {
                margin:20px 0 0 0;
                max-width:100%;
            }
        }
    }
}