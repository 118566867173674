/**
 * Gallery List.
 */

.gallery-list {
    @extend %block;

    &__item {
        @extend %block;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: #FFFFFF;
        margin-top: 2rem;
        text-decoration: none;

        &-overlay {
            @extend %block;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background:rgba(239, 239, 239, 0.75);
            opacity: 0;
            z-index: 1;
            transition: opacity 0.5s ease;

            @include breakpoint(0, 600px) {
                display: none;
            }

            .button {
                text-decoration:none!important;
            }
        }

        &-image {
            @extend %block;

            img {
                @extend %block;
            }
        }

        &-text {
            @extend %block;
            display: flex;
            flex-direction: column;
            background: $blue;
            align-items: center;
            justify-content: center;
            padding: 10px;
            min-height: 78px;
            flex-grow: 1;
            width: calc(100% - 20px);
            transition: background 0.5s ease;
            text-decoration: none !important;

            &-title {
                @extend %block;
                color: #FFFFFF;
                text-transform: uppercase;
                text-align: center;
                font: 20px/1 $title-font;

                a {
                    color: #FFFFFF;
                    text-decoration: none;
                }
            }

            &-count {
                @extend %block;
                color: #FFFFFF;
                font-size: 13px;
                line-height: 13px;
                text-align: center;
                margin-top: 3px;
                @include montserrat();

                i {
                    margin-right: 5px;
                }
            }
        }

        &:hover,
        &:active {
            .gallery-list__item-overlay {
                opacity: 1;
            }
        }
    }

    &-container {
        @extend %block;
        padding: 60px 0;
        padding-top: calc(60px - 2rem);
    }
}
