.landing-boxes {
    >.row {
        // margin-left: 0;
        // margin-right: 0;
        justify-content: space-between;
        margin: 35px 0;
    }

    &__box {
        margin: 0 0 17px;
        min-height: 406px;
        color: white;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #1a3563;
        position: relative;
        display: flex;

        &:nth-child(1),
        &:nth-child(2) {
            margin-top: 17px;
        }

        &-image {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
        }

        &-border {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                border: 1px solid white;
                width: calc(100% - 24px);
                height: calc(100% - 24px);
            }
        }

        &-space {
            padding: 35px 15px 15px;

            @media screen and (max-width: 797px) {
                background: rgba(0, 0, 0, 0.5);
            }
        }

        &background {
            background-color: #fff;
        }

        &__content {
            font: .9rem/1 $body-font;
            color: white;

            &-title,
            p {
                strong {
                    font-family: 'Montserrat Bold', sans-serif;
                    font-size: 15px;
                }
            }

            &-title {
                margin-bottom: 21px;
                text-transform: uppercase;
                font: 700 29px/1 $title-font;

                &-red {
                    color: $red;

                    &::first-line {
                        color: white;
                    }

                    @media screen and (max-width:992px) {
                        text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.6);
                    }
                }
            }

            p {
                font: 100 13px/1.5 $body-font;
                padding: 8px 0;
            }
        }
    }

    @media only screen and (min-width: 48em) {
        .col-sm-6 {
            flex-basis: 49%;
            max-width: 49%;
        }
    }

    .img-responsive {
        width: 100%;
        height: auto;
        display: block;
    }
}