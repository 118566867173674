/**
 * Featured Product Slider.
 */

.featured-products {
    @extend %block;
    background: #dbedf3;
    padding: 40px 0;

    &-slider {
        @extend %reset;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__container {
            @extend %block;
            width: 0;
            margin: 0 auto;
            overflow: hidden;
        }

        &__inner {
            @extend %reset;
            display: flex;
            flex-direction: row;
            position: absolute;
            white-space: nowrap;
            width: 200000px;
            top: 0;
            left: 0;
            overflow: hidden;
            align-self: center;
            transition: left 0.5s ease;
        }

        &__placeholder {
            @extend %reset;
            display: flex;
            flex-direction: row;
            white-space: nowrap;
            width: 200000px;
            z-index: -1;
            opacity: 0;
        }

        &__item {
            @extend %reset;
            display: flex;
            flex-direction: column;
            width: 224px;
            flex: 0 0 224px;
            background: #FFFFFF;
            border: 1px solid #e0e0e0;
            border-left: none;
            padding: 30px;
            white-space: normal;

            &-overlay {
                @extend %reset;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                max-width: calc(85% - 50px);
                max-height: calc(85% - 50px);
                margin: auto;
                z-index: 5;
                background: rgba(255, 255, 255, 0.6);
                border: 1px solid #d1d1d1;
                padding: 25px;
                opacity: 0;
                transition: opacity 0.2s ease;

                .button {
                    margin-top: 10px;
                }
            }

            &-social {
                @extend %block;

                &__item {
                    @extend %reset;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                    background: #b2b2b2;
                    padding-top: calc(33% - (20px / 3));
                    width: calc(33% - (20px / 3));
                    transition: background 0.5s ease;

                    i {
                        @extend %block;
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        color: #FFFFFF;
                        font-size: 22px;
                        line-height: 22px;
                        text-align: center;
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    &:hover,
                    &:active {
                        background: #9e9999;
                        cursor: pointer;
                    }
                }
            }

            &-image {
                @extend %block;
                display: flex;
                flex-direction: column;
                text-align: center;
                flex-grow: 1;
                align-items: center;
                justify-content: center;

                img {
                    @extend %block;
                    width: auto;
                    max-width: 100%;
                }
            }

            &-title {
                @extend %block;
                text-align: center;
                font-size: 13px;
                line-height: 19px;
                color: #7c7c7c;
                margin-top: 20px;

                a {
                    color: #7c7c7c;
                    text-decoration: none;
                }
            }

            &-price {
                @extend %block;
                text-align: center;
                color: $blue;
                font-size: 19px;
                line-height: 19px;
                font-weight: bold;
                margin-top: 10px;
            }

            &:hover {
                .item-overlay {
                    opacity: 1;
                }
            }

            &:first-child {
                border-left: 1px solid #e0e0e0;
            }
        }
    }

    &-nav {
        @extend %block;
        text-align: center;
        margin-top: 27px;

        &__item {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            margin-left: 9px;
            background: $blue;
            border-radius: 50%;
            width: 18px;
            height: 18px;

            &:first-child {
                margin-left: 0;
            }

            &:hover,
            &--active {
                background: $green;
                cursor: pointer;
            }
        }
    }

    &-button {
        @extend %block;
        text-align: center;
        margin-top: 19px;
    }

    &-tab {
        @extend %block;
        margin-top: 40px;
        display: none;
    }

    &-tabs {
        @extend %block;
        text-align: center;

        &__item {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;

            &:before {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                content: '|';
                color: $blue;
                transition: font-size 0.5s ease;
                font: 20px/1 $title-font;

                @include breakpoint(0, 768px) {
                    font-size: 14px;
                }
            }

            &:first-child:before {
                display: none;
            }

            a {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                text-transform: uppercase;
                color: $blue;
                text-decoration: none;
                margin: 0 8px;
                transition: font-size 0.5s ease;
                font: 20px/1 $title-font;

                &:hover,
                &:active,
                &.active {
                    cursor: pointer;
                    color: #404041;
                    text-decoration: none;
                }

                @include breakpoint(0, 768px) {
                    font-size: 14px;
                }
            }
        }
    }
}
