/**
 * Landing Newsletter Signup.
 */

.newsletter-container {
    @extend %block;
    margin-bottom: 60px;
}

.landing-newsletter {
    @extend %block;
    background: #FFFFFF;

    .col-first {
        background: url('/assets/images/landing/newsletter-background.jpg') no-repeat bottom right transparent;
    }

    &__inner {
        @extend %block;
        width: calc(100% - 40px);
        padding: 20px;
        background: url('/assets/images/test/newsletter.jpg') no-repeat top right transparent;

        p {
            @extend %block;
            text-transform: uppercase;
            color: $green;
            font: 27px/1 $title-font;

            &.large {
                font-size: 78px;
                line-height: 78px;
                color: $blue;
                font-family: 'Montserrat Bold', sans-serif;

                @include breakpoint(0, 570px) {
                    font-size: 60px;
                    line-height: 60px;
                }
            }

            @include breakpoint(0, 570px) {
                font-size: 22px;
                line-height: 22px;
                text-align: center;
            }
        }

        form {
            margin-top: 10px;
        }

        input[type=text] {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 18px - 132px - 22px);
            border: 1px solid #c7c7c7;
            height: 39px;
            line-height: 39px;
            color: #000000;
            font-size: 13px;
            padding: 0 10px;
            font-family: 'Montserrat', sans-serif;

            @include breakpoint(0, 570px) {
                display: block;
                width: calc(100% - 22px);
            }
        }

        button.button {
            width: 132px;
            margin-left: 18px;

            @include breakpoint(0, 570px) {
                display: block;
                width: 100%;
                text-align: center;
                margin-left: 0;
                margin-top: 20px;
            }
        }

        @include breakpoint(0, 570px) {
            background: transparent;
        }
    }

    &__triangle {
        @extend %block;
        position: absolute;
        width: 186px;
        height: 186px;
        left: 5px;
        top: 50%;
        margin-top: -99px;
        background: $blue;
        transform: rotate(45deg);
        border: 12px solid #FFFFFF;
        box-shadow: 0 0 0 3px #e8e8e8;

        &-inner {
            @extend %block;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            transform: rotate(-45deg);

            p,
            h3 {
                @extend %block;
                text-align: center;
                color: #FFFFFF;
            }

            hr {
                @extend %block;
                width: 116px;
                height: 1px;
                background: #FFFFFF;
            }

            p {
                text-transform: uppercase;
                // font-family: 'Dosis', sans-serif;
                // font-size: 14px;
                // line-height: 22px;
                font: 14px/1.5 $title-font;
            }

            h3 {
                font-size: 34px;
                line-height: 64px;
                font-weight: bold;
                font-family: 'Montserrat Bold', sans-serif;
            }
        }

        @include breakpoint(0, 991px) {
            display: none;
        }
    }
}
