.testimonials {
    margin-bottom: 52px;

    @media screen and (max-width: 797px) {
        margin: -15px 0 10px 0;

        >.container {
            padding: 0;
            max-width: calc(100% - 15px);
        }
    }

    &__content {
        background: url('../images/testimonial-bg.png') no-repeat #1a3563;
        color: white;
        text-align: center;
        padding: 28px 35px 18px;
        position: relative;
        margin: 0 15px;

        @media screen and (max-width: 797px) {
            margin: 0;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            border: 1px solid white;
            width: calc(100% - 30px);
            height: calc(100% - 30px);
        }

        &-stars {
            @extend %block;
            margin-bottom: 12px;
            font-size: 23px;
            line-height: 23px;
        }

        &-title {
            @extend %block;
            text-transform: uppercase;
            font: 700 23px/1 $title-font;
        }

        // &-border {
        //     border: 9px $border-color solid;
        // }
    }

    &-slider {
        display: flex;
        flex-direction: row;
        overflow: hidden;

        &__item,
        &__item p {
            // color: #7c7c7c;
            font: 18px/23px $title-font;
        }

        &__item {
            // padding-top: 55px;
            // padding-bottom: 20px;
            // text-align: center;
            padding: 7px 0;
            flex: 0 0 100%;

            &-author {
                color: $red;
                font-weight: 700;
                margin: 15px 0 0;
            }
        }
    }

    @media only screen and (max-width: 48em) {
        &__content {
            background-image: none;
        }
    }
}