/**
 * BalloonsGalore Template.
 */

/* Vendor */
@import '../../../node_modules/flexboxgrid/css/flexboxgrid';

/* Base */
@import 'partials/base';

/* Fixes for Firefox & other */
@import 'partials/fixes';

/* Partials */
@import 'partials/reset';
@import 'modules/partials';

/* Layout Sections */
@import 'layout/header';
@import 'layout/footer';
@import 'layout/containers';

/* Parent Structure */
html,
body {
    min-height: 100%;
}

body {
    // display: -ms-flexbox;
    // display: -webkit-flex;
    // display: flex;
    // -webkit-flex-direction: column;
    // -ms-flex-direction: column;
    // flex-direction: column;
	background: #FFFFFF;
	font-size: 0;
    font-family: $body-font;
    overflow-x:hidden;

	* {
		outline: none;
		-webkit-touch-callout: none;
		-webkit-tap-highlight-color: transparent;
		@include font-smoothing(antialiased);
	}
}

.vload {
    display: none;

    &--ready {
        display: inline;
    }
}

.product-page-options__location-button {
    img {
        opacity: 0;
        position: absolute;
        border: 1px solid #bbbbbb;
        background: #fff;
        padding: 3px;
        right: 0;
        bottom: 35px;
        max-width: 200px;
        width: 20px;
        @include transition(all 0.3s ease);
    }
    &:hover {
        img {
            opacity: 1;
            width: 120px;            
        }
    }

}
