// Second Gallery on the Homepage
.landing-gallery2 {
    padding: 35px 0;
    background-color: #e7e7e7;

    &-title {
        margin: 0 0 25px;
        font: 600 38px/1 $title-font;
        color: #1a3563;
        text-align: center;
        text-transform: uppercase;
    }

    &__body {
        .slick-dotted.slick-slider {
            margin: 0;

            .slick-list {
                padding: 20px 10px;
                background: white;

                @media screen and (max-width:797px) {
                    padding: 0;
                }
            }
        }

        .landing-gallery__slick-slides-item img {
            height: 252px;
        }
    }

    .button {
        margin: 15px auto 0;
    }
}