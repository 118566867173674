.landing-description {
    @extend %block;
    background: #e7e7e7;
    margin: 0;
    max-width: 100%;
    width: 100%;
    padding: 35px 0;

    @media screen and (max-width: 797px) {
        padding: 20px 0;
    }

    &__box {
        @extend %block;
        text-align: center;
        max-width: 1140px;
        margin: auto;
        color: #2e4267;
    }

    &__title {
        @extend %block;
        margin-bottom: 5px;
        text-transform: uppercase;
        font: 700 23px/1 $title-font;

        @media screen and (max-width: 797px) {
            font-size: 20px;
        }
    }

    p {
        padding: 8px 0;
        font: 600 18px/1.7 $title-font;

        @media screen and (max-width: 797px) {
            font: 400 16px/1.2 $title-font;
        }
    }
}