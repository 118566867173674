/**
 * Product Listing.
 */

.product-list {
    @extend %block;
    padding-bottom: 100px;

    &__col {
        display: flex;
    }

    &__item {
        @extend %block;
        display: flex;
        flex-direction: column;
        width: calc(100% - 52px);
        flex-grow: 1;
        border: 1px solid #e0e0e0;
        padding: 25px;
        margin-top: 30px;
        text-align: center;

        &-price {
            @extend %block;
            margin-top: 10px;
            font-size: 14px;
            color: #7b7b7b;

            span {
                color: $red;
                font-weight: 700;
                display: inline-block;
                margin: 3px 0;

                &.from {
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            small {
                font-size: 15px;
            }
        }

        &-image {
            @extend %block;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;

            &-main {
                @extend %reset;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }

            &-placeholder {
                @extend %block;
                opacity: 0;
            }
        }

        &-title {
            @extend %block;
            text-decoration: none;
            margin-top: 10px;
            font: 600 16px/1.2 'Dosis';
            color: $dark-blue;

            &:hover,
            &:active {
                text-decoration: none;
            }
        }

        &-badge {
            @extend %block;
            width: 50px;
            height: 50px;
            text-align: center;
            position: absolute;
            top: 2px;
            left: 2px;
            color: #FFFFFF;
            text-transform: uppercase;
            background: #000000;
            z-index: 1;
            // font-size: 17px;
            // line-height: 50px;
            // font-family: 'Dosis', sans-serif;
            font: 17px/50px $title-font;

            &--sale {
                background: #b3260f;
            }

            &--designer {
                background: $green;
            }
        }

        &:hover {
            .item-overlay {
                opacity: 1;
            }
        }
    }

    small {
        &.discount {
            font-size: 10px;
        }
    }

    .prod-details {
        margin: 15px 0 0 18px;

        li {
            font-size: 13px;
            font-weight: 700;
            margin: 0 0 5px 0;
            list-style: disc;
            color: #7b7b7b;

            a {
                color: #7b7b7b;
            }
        }
    }
}

.item-overlay {
    @extend %reset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: calc(85% - 50px);
    max-height: calc(85% - 50px);
    margin: auto;
    z-index: 1;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid #d1d1d1;
    padding: 25px;
    opacity: 0;
    min-height: 0;
    min-width: 0;
    transition: opacity 0.2s ease;

    form {
        @extend %block;
        width: calc(100% + 40px);
    }

    &-social {
        @extend %block;

        &__item {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            background: #b2b2b2;
            padding-top: calc(33% - (20px / 3));
            width: calc(33% - (20px / 3));
            transition: background 0.5s ease;

            i {
                @extend %block;
                position: absolute;
                width: 22px;
                height: 22px;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                color: #FFFFFF;
                font-size: 22px;
                line-height: 22px;
                text-align: center;
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover,
            &:active {
                background: #9e9999;
                cursor: pointer;
            }
        }
    }

    .button {
        margin-top: 10px;
    }

    @include breakpoint(0, 500px) {
        display: none;
    }
}