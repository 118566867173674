/**
 * Page Header.
 */

.page-header {
    @extend %block;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 441px;
    max-height: 441px;
    background: url('/assets/images/gallery/gallery-header.jpg') no-repeat center #74bdd4;
    transition: background 0.5s ease,
        min-height 0.5s ease,
        max-height 0.5s ease;

    &__heading {
        @extend %block;
        color: #FFFFFF;
        transition: font-size 0.5s ease,
        line-height 0.5s ease;
        // font-size: 68px;
        // line-height: 68px;
        // font-weight: bold;
        // font-family: 'Open Sans', sans-serif;
        font: bold 68px/1 $body-font;

        span {
            color: $dark-blue;
        }

        @include breakpoint(0, 900px) {
            text-align: center;
        }

        @include breakpoint(0, 600px) {
            font-size: 25px;
            line-height: 25px;
        }
    }

    &__tagline {
        @extend %block;
        color: #FFFFFF;
        margin-top: 15px;
        transition: font-size 0.5s ease,
        line-height 0.5s ease;
        // font-size: 17px;
        // line-height: 17px;
        // font-weight: bold;
        // font-family: 'Open Sans', sans-serif;
        font: bold 17px/1 $body-font;

        @include breakpoint(0, 900px) {
            text-align: center;
        }

        @include breakpoint(0, 600px) {
            font-size: 14px;
            line-height: 14px;
        }
    }

    @include breakpoint(0, 900px) {
        background-position: 900px center;
        max-height: 350px;
        min-height: 350px;
    }

    @include breakpoint(0, 600px) {
        min-height: 150px;
        max-height: 150px;
    }
}
