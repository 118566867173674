/**
 * Footer Elements.
 */

.footer {
    @extend %block;
    @include box-shadow(0 -6px 0 0 rgba(228, 236, 239, 1));

    &__social {
        @extend %block;
        text-align: center;

        &:before {
            @extend %block;
            position: absolute;
            left: 0;
            top: 50%;
            height: 1px;
            background: #CCCCCC;
            content: '';
        }
    }

    &__header {
        @extend %block;
        color: $dark-blue;
        text-transform: uppercase;
        font: 18px/1 $title-font;

        @include breakpoint(0, 630px) {
            text-align: center;
        }
    }

    &__content {
        @extend %block;

        p,
        ul,
        ul li {
            @extend %block;
        }

        p,
        ul li,
        ul li:before,
        p a,
        ul li a {
            color: #7c7c7c;
            font-size: 13px;
            line-height: 22px;
            text-decoration: none;
            @include montserrat();
        }

        p a,
        ul li a {

            &:hover,
            &:active {
                text-decoration: underline;
            }
        }

        ul li {
            &:before {
                padding-right: 3px;
                font: normal normal normal 14px/1 FontAwesome;
                content: "\f105";
            }
        }

        @include breakpoint(0, 630px) {
            text-align: center;
        }
    }

    .responsive-col {
        @include breakpoint(0, 630px) {
            display: none;
        }
    }

    .responsive-image {
        @include breakpoint(0, 630px) {
            display: none;
        }
    }

    // &-newsletter {
    //     @extend %block;

    //     &__input {
    //         @extend %block;
    //         width: calc(100% - 10px);
    //         border: 1px solid #c7c7c7;
    //         font-size: 13px;
    //         color: #a3a3a3;
    //         height: 36px;
    //         line-height: 36px;
    //         padding: 0 5px;
    //         margin-top: 5px;
    //     }

    //     &__button {
    //         @extend %reset;
    //         display: inline-block;
    //         vertical-align: middle;
    //         background: #cecece;
    //         color: #FFFFFF;
    //         text-transform: uppercase;
    //         margin-top: 16px;
    //         padding: 0 30px;
    //         height: 36px;
    //         font-size: 17px;
    //         line-height: 36px;
    //         font-family: 'Dosis', sans-serif;
    //         font: 17px/36px $title-font;
    //         cursor: pointer;
    //         transition: background 0.5s ease;

    //         &:hover,
    //         &:active {
    //             background: #aca6a6;
    //         }

    //         @include breakpoint(0, 630px) {
    //             display: block;
    //             width: 100%;
    //             text-align: center;
    //         }
    //     }
    // }

    // &-contact {
    //     @extend %block;
    //     text-align: center;
    //     margin-top: 20px;
    //     margin-bottom: 50px;
    //     transition: margin-bottom 0.5s ease;

    //     &__item {
    //         @extend %reset;
    //         display: inline-block;
    //         vertical-align: middle;
    //         margin-left: 30px;
    //         color: $dark-blue;
    //         text-transform: uppercase;
    //         font-weight: bold;
    //         font-size: 20px;
    //         line-height: 20px;
    //         font-family: 'Dosis', sans-serif;
    //         font: bold 20px/20px $title-font;
    //         transition: font-size 0.5s ease, line-height 0.5s ease;

    //         .fa {
    //             color: $green;
    //         }

    //         a,
    //         a:hover,
    //         a:active {
    //             color: $dark-blue;
    //             text-decoration: none;
    //         }

    //         &:first-child {
    //             margin-left: 0;
    //         }

    //         @include breakpoint(0, 630px) {
    //             margin-left: 0;
    //             display: block;
    //             text-align: center;
    //             font-size: 16px;
    //             line-height: 22px;
    //         }
    //     }

    //     @include breakpoint(0, 630px) {
    //         margin-bottom: 20px;
    //     }
    // }

    &-upper {
        @extend %block;
        background: #FFFFFF;
        padding-top: 30px;
        padding-bottom: 15px;
    }

    &-lower {
        @extend %block;
        display: flex;
        align-items: center;
        background: $dark-blue;
        min-height: 50px;

        .container {
            display: flex;
            flex-direction: row;

            @include breakpoint(0, 996px) {
                flex-direction: column;
            }
        }

        &__text {
            @extend %reset;
            display: flex;
            align-items: center;
            color: #FFFFFF;
            font-size: 13px;
            line-height: 13px;

            @media screen and (max-width: 767px) {
                &:nth-child(2) {
                    font-size: 0;
                    margin-left: 15px;
                }

                img {
                    margin: 0;
                    width: 100%;
                    height: auto;
                }
            }

            a {
                color: #FFFFFF;
                text-decoration: none;

                &:hover,
                &:active {
                    color: #FFFFFF;
                    text-decoration: underline;
                }
            }

            img {
                vertical-align: middle;
            }

            @include breakpoint(0, 996px) {
                text-align: center;
                justify-content: center;
                margin-top: 8px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        &__image {
            &--left {
                margin-right: 5px;
            }

            &--right {
                margin-left: 35px;
            }
        }

        @include breakpoint(0, 996px) {
            height: auto;
            padding: 15px 0;
        }
    }

    &--designer {
        @include breakpoint(0, 991px) {
            display: none;
        }
    }

    .footer-upper {
        $grey: #7c7c7c;
        $bold: 700 1.2rem/1 $title-font;
        $text: 400 13px/22px 'Montserrat Regular', sans-serif;
        padding: 35px 0;

        div {
            color: $grey;
            font: $text;
        }

        @media screen and (max-width:600px) {
            .col-xs-12 {
                &:nth-child(4) {
                    display: none;
                }
            }
        }

        @media screen and (max-width:991px) {
            padding: 15px 0;

            .col-xs-12 {

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(4) {
                    display: none;
                }

                &:nth-child(3) {
                    .title {
                        display: none;
                    }

                    ul li {
                        justify-content: center;

                        &:nth-child(2) {
                            display: none;
                        }
                    }
                }
            }
        }

        .title {
            color: $dark-blue;
            text-transform: uppercase;
            font: $bold;
            margin: 5px 0;
        }

        ul li {
            display: flex;
            font: $text;

            @media screen and (max-width: 797px) {
                &:last-child {
                    a:last-child {
                        i.social {
                            margin: 0;
                        }
                    }
                }
            }
        }

        a {
            color: $grey;
            font: $text;
            text-decoration: none;
            transition: .35s;

            @media screen and (max-width: 797px) {
                max-width: 65vw;
                text-align: center;
                margin-bottom: 10px;
            }

            &:hover {
                color: $dark-blue;
            }

            i {
                margin: 5px 5px 5px 0;

                @media screen and (max-width: 797px) {
                    margin: 0 5px 0 0;
                }
            }

            i.social {
                color: white;
                background: $dark-blue;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.5rem;
                transition: .35s;
                margin: 10px 10px 10px 0;

                @media screen and (max-width: 797px) {
                    margin: 0 10px 0 0;
                }

                &:hover {
                    background: $red;
                }
            }

            &.contact-link {
                font: $bold;
                color: $dark-blue;
                font-size: 1.4rem;

                @media screen and (max-width: 797px) {
                    font-size: 1.1rem;
                }

                &:hover {
                    color: $red;
                }
            }
        }

        input[type=text] {
            width: calc(100% - 20px);
            padding: 0 10px;
            height: 35px;
            margin: 10px 0;
            border: 1px solid rgba(124, 124, 124, 0.4);

            &::placeholder {
                color: $grey;
            }

            &:focus {
                &::placeholder {
                    color: rgba(124, 124, 124, 0.5);
                }
            }
        }

        button {
            border: 0;
            background-color: #cecece;
            border-radius: 2px;
            width: 130px;
            height: 35px;
            color: white;
            font: $bold;
            text-transform: uppercase;
            transition: .35s;

            &:hover {
                background-color: $dark-blue;
            }
        }
    }
}