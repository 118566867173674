/**
 * Checkout Elements.
 */

.checkout {
    @extend %block;

    &-date {
        @extend %block;
        display: flex;
        flex-direction: row;
        margin-top: 20px;

        &__label {
            @extend %reset;
            display: inline-flex;
            vertical-align: middle;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: center;
            padding-right: 10px;
            font-size: 13px;
            line-height: 13px;
            color: #7c7c7c;
            @include montserrat();
        }

        &__container {
            @extend %reset;
            display: inline-flex;
            flex-direction: row;
            vertical-align: middle;
            flex-grow: 1;
            flex-shrink: 1;
            border: 1px solid #c9c9c9;

            @media (max-width:500px) {
                margin-top: 5px;
            }
        }

        &__input {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            background: transparent;
            height: 34px;
            line-height: 34px;
            padding: 0 10px;
            appearance: none;
            border-radius: 0;
            flex-grow: 1;
            flex-shrink: 1;
            font-size: 13px;
            color: #7c7c7c;
            z-index: 2;
            @include montserrat();
        }

        &__icon {
            @extend %reset;
            display: inline-flex;
            vertical-align: middle;
            width: 34px;
            height: 34px;
            background: #b2b2b2;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            font-size: 17px;
            line-height: 17px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }

        @media (max-width:500px) {
            flex-direction: column;
        }
    }

    &-shipping {
        @extend %block;
        margin-top: 20px;

        &__method {
            @extend %block;
            width: calc(100% - 2px - 10px);
            display: flex;
            flex-direction: row;
            flex-grow: 0;
            flex-shrink: 0;
            border: 1px solid #dfdfdf;
            margin-top: 13px;
            padding: 5px;

            &-label {
                @extend %block;
                text-align: center;
                margin-top: 5px;
                font-size: 11px;
                line-height: 11px;
                color: #7c7c7c;
                @include montserrat();

                @media (max-width:500px) {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 5px;
                    width: auto;
                }
            }

            &-radio {
                @extend %block;
                width: 21px;
                height: 21px;
                background: #b2b2b2;
                appearance: none;
                border-radius: 50%;

                &:checked:before {
                    @extend %block;
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    content: '';
                    background: $blue;
                    margin: auto;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    position: absolute;
                }

                @media (max-width:500px) {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            &-title {
                @extend %block;
                color: $blue;
                text-transform: uppercase;
                font: 20px/1 $title-font;
            }

            &-text,
            &-price,
            &-option {
                @extend %reset;
                display: inline-flex;
                flex-direction: column;
                vertical-align: middle;
                background: #f0f0f0;
                flex-grow: 0;
                flex-shrink: 0;
                justify-content: center;
                padding: 10px 15px;

                @media (max-width:500px) {
                    display: block;
                    width: calc(100% - 30px) !important;
                    text-align: center;
                    margin-top: 5px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            &-price,
            &-option {
                margin-left: 5px;

                @media (max-width:500px) {
                    margin-left: 0;
                }
            }

            &-text {
                width: 100%;
                flex-grow: 1;
                flex-shrink: 1;
            }

            &-price {
                width: 65px;
            }

            &-option {
                width: 62px;
                padding-left: 0;
                padding-right: 0;
                align-items: center;

                @media (max-width:500px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            &--disabled:before {
                @extend %block;
                height: 100%;
                z-index: 2;
                background: rgba(0, 0, 0, 0.3);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                content: '';
            }

            @media (max-width:500px) {
                flex-direction: column;
            }
        }
    }

    &-address {
        @extend %block;
        margin-top: 0;
        max-height: 0;
        white-space: nowrap;
        overflow: hidden;
        transition: max-height 0.5s ease,
            margin-top 0.5s ease;

        &--visible {
            margin-top: 10px;
            max-height: 500px;

            @media (max-width:500px) {
                max-height: 1200px;
            }
        }
    }

    &-toggle {
        @extend %block;
        display: flex;
        flex-direction: row;

        &__radio {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            margin-left: 18px;

            &-input,
            &-label {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
            }

            &-input {
                width: 39px;
                height: 39px;
                border-radius: 50%;
                background: #b2b2b2;
                appearance: none;

                &:checked:before {
                    @extend %block;
                    width: 19px;
                    height: 19px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    content: '';
                    border-radius: 50%;
                    background: $blue;
                }
            }

            &-label {
                font-size: 13px;
                line-height: 13px;
                color: #7c7c7c;
                margin-left: 6px;
                @include montserrat();
            }

            &:first-child {
                margin-left: 0;
            }
        }

        p,
        &__inner {
            @extend %reset;
            display: flex;
            flex-direction: row;

            @media (max-width:500px) {
                display: block;
                width: 100%;
            }
        }

        p {
            flex-grow: 1;
            flex-shrink: 1;
            font-size: 13px;
            line-height: 13px;
            color: #7c7c7c;
            align-items: center;
            @include montserrat();
        }

        &__inner {
            flex-grow: 0;
            flex-shrink: 0;

            @media (max-width:500px) {
                margin-top: 5px;
            }
        }

        @media (max-width:500px) {
            flex-direction: column;
        }
    }

    &-heading {
        @extend %block;
        color: $blue;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-top: 25px;
        font: 20px/1 $title-font;

        .fa {
            margin-right: 5px;
        }

        &:first-child {
            margin-top: 0;
        }

        &--margin-mobile {
            @media (max-width:500px) {
                margin-top: 25px !important;
            }
        }
    }

    &-box {
        @extend %block;
        width: calc(100% - 50px - 2px);
        border: 1px solid #dedede;
        padding: 25px;
        text-align: left;

        &__toggle {
            @extend %block;
            display: flex;
            flex-direction: row;
            margin-top: 25px;

            input {
                @extend %block;
                appearance: none;
                width: 15px;
                height: 15px;
                background: #b2b2b2;
                border-radius: 50%;
                flex-grow: 0;
                flex-shrink: 0;

                &:checked:before {
                    @extend %block;
                    width: 7px;
                    height: 7px;
                    background: $blue;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    content: '';
                    border-radius: 50%;
                }
            }

            label {
                @extend %reset;
                flex-grow: 1;
                flex-shrink: 1;
                font-size: 13px;
                line-height: 17px;
                color: #7c7c7c;
                margin-left: 5px;
                @include montserrat();
            }
        }

        p {
            @extend %block;
            font-size: 13px;
            line-height: 17px;
            color: #7c7c7c;
            @include montserrat();

            &.red {
                color: #bf0d0d;
            }
        }

        &__bottom {
            @extend %block;
            text-align: right;
            margin-top: 10px;

            .button--green {
                width: 180px;
                text-align: center;
                padding-left: 0;
                padding-right: 0;
            }

            .button--left {
                width: auto !important;
                float: left;
            }

            &--mid {
                .button {}
            }

            @media (max-width:500px) {
                .button {
                    display: block;
                    text-align: center;
                    padding-left: 0;
                    padding-right: 0;
                    width: 100% !important;

                    &:first-child {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        &__divider {
            @extend %block;
            height: 1px;
            background: #d3d3d3;
            margin-top: 10px;
        }

        &__input {
            @extend %block;
            margin-top: 15px;

            &-label {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                width: 150px;
                font-size: 13px;
                line-height: 13px;
                color: #7c7c7c;
                @include montserrat();

                @media (max-width:500px) {
                    display: block;
                    width: 100%;
                }
            }

            &-input {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 20px - 2px - 150px);
                margin-top: 5px;
                border: 1px solid #dfdfdf;
                background: #FFFFFF;
                padding: 0 10px;
                height: 34px;
                line-height: 34px;
                color: #7c7c7c;
                font-size: 13px;
                @include montserrat();

                @media (max-width:500px) {
                    display: block;
                    width: calc(100% - 20px - 2px);
                }
            }

            &-action {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 150px);
                margin-top: 5px;

                .button {
                    display: inline-block;
                    height: 36px;
                    line-height: 36px;
                    padding: 0;
                    width: 120px;
                    text-align: center;
                    font-size: 11px;
                    @include montserrat();

                    @media (max-width:500px) {
                        width: 100%;
                        display: block;
                    }
                }

                .checkout-box__input-input {
                    margin-top: 0;
                    border-right: none;
                    width: calc(100% - 1px - 20px - 120px);

                    @media (max-width:500px) {
                        width: calc(100% - 1px - 20px);
                        display: block;
                        border-right: 1px solid #dfdfdf;
                        border-bottom: none;
                    }
                }

                @media (max-width:500px) {
                    width: 100%;
                    display: block;
                }
            }

            select.checkout-box__input-input {
                height: 36px;
                line-height: 36px;
                appearance: none;
                border-radius: 0;
                width: calc(100% - 150px);
                background: url('/assets/images/select.jpg') no-repeat center right #FFFFFF;

                @media (max-width:500px) {
                    width: 100%;
                    display: block;
                }
            }

            &:first-child {
                margin-top: 0;
            }

            &--block {
                margin-top: 20px;

                .checkout-box__input {
                    &-label {
                        @extend %block;
                        font-size: 13px;
                        line-height: 13px;
                        color: #7c7c7c;
                        @include montserrat();
                    }

                    &-input {
                        @extend %block;
                        width: calc(100% - 20px - 2px);
                        margin-top: 5px;
                        border: 1px solid #dfdfdf;
                        background: #FFFFFF;
                        padding: 0 10px;
                        height: 34px;
                        line-height: 34px;
                        color: #7c7c7c;
                        font-size: 13px;
                        @include montserrat();
                    }
                }
            }

            &--textarea {
                margin-top: 15px;
                .checkout-box__input {
                    &-label {
                        display: block !important;
                        width: 100% !important;
                    }
                }

                textarea {
                    @extend %block;
                    width: calc(100% - 20px - 2px);
                    margin-top: 5px;
                    border: 1px solid #dfdfdf;
                    background: #FFFFFF;
                    padding: 5px 10px;
                    height: 125px;
                    line-height: 18px;
                    color: #7c7c7c;
                    font-size: 13px;
                    @include montserrat();
                }
            }
        }

        &__button {
            min-width: 137px;
            text-align: center;
            margin-top: 10px;
        }

        &--small {
            display: inline-block;
            vertical-align: top;
            width: calc(360px - 50px - 2px);
            margin-left: 30px;

            &:first-child {
                margin-left: 0;
            }

            @media (max-width:850px) {
                display: block;
                width: calc(100% - 2px - 50px);
                margin-left: 0;
                margin-top: 20px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        &--complete {
            width: calc(557px - 50px - 2px);
            margin: 0 auto;

            @media (max-width:850px) {
                width: calc(100% - 2px - 50px);
            }
        }

        &--delivery {
            width: calc(557px - 50px - 2px);
            margin: 0 auto;

            @media (max-width:850px) {
                width: calc(100% - 2px - 50px);
            }
        }
    }

    &-step {
        @extend %block;
        margin-top: 35px;
    }

    &-steps {
        @extend %block;
        height: 22px;
        margin-top: 16px;

        &__step {
            @extend %block;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: #b7b7b7;
            position: absolute;
            top: 0;
            left: 0;

            &-label {
                @extend %block;
                width: 150px;
                position: absolute;
                top: calc(100% + 5px);
                left: 50%;
                text-align: center;
                margin-left: -75px;
                font-size: 13px;
                color: #7c7c7c;
                @include montserrat();
            }

            &--1 {
                left: 0;
            }

            &--2 {
                left: calc(33% - 11px);
            }

            &--3 {
                left: calc(66% - 11px);
            }

            &--4 {
                left: calc(100% - 22px);
            }

            &--active {
                background: $red;
            }
        }

        &:before {
            @extend %block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 4px;
            content: '';
            margin: auto 0;
            background: #b7b7b7;
        }
    }

    &-container {
        @extend %block;
        padding-top: 25px;
        padding-bottom: 25px;

        .button {
            background:$dark-blue;
            max-width:217px;
            
            &--left {
                background:#04aae7;
            }
        }
    }
    
    &-header {
        @extend %block;
        width: calc(100% - 2px);
        background: $dark-blue;
        border: 1px solid #dedede;
        height: 56px;
        overflow: hidden;

        &-summary {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            height: 56px;

            &__block {
                @extend %reset;
                display: inline-block;
                vertical-align: middle;
                height: 56px;
                color: #FFFFFF;
                padding: 0 20px;
                background:#04aae7;
                transition: padding 0.5s ease;
                // font-size: 15px;
                // line-height: 56px;
                // font-family: 'Open Sans', sans-serif;
                // font-weight: bold;
                font: bold 15px/56px $body-font;

                i {
                    font-size: 20px;
                }

                &-link {
                    text-transform: uppercase;
                    color: #FFFFFF;
                    font-size: 12px;
                }

                &--dark {
                    background:#098ebf;
                }

                &--no-padding-left {
                    padding-left: 0;
                }

                @media (max-width:370px) {
                    padding: 0 10px;
                }
            }
        }

        img {
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            transition: bottom 0.25s ease,
                top 0.25s ease,
                opacity 0.25s ease;

            @media (max-width:940px) {
                bottom: 100%;
                top: auto;
                opacity: 0;
            }
        }
    }
}

.checkout-box__bottom .button.checkout-prev {
    @media (max-width:500px) {
        display:none;
    }
}

#payment-btns {
    float:right;

    @media (max-width:500px) {
        float:none;

        margin:auto;
        width:200px;
    }
}