/**
 * Resets
 */

%reset {
	width: auto;
	height: auto;
	margin: 0;
	padding: 0;
	border: none;
	list-style: none;
	position: relative;
    background: transparent;
	font-size: 0;

	&:hover {
		text-decoration: none;
		outline: none;
	}
}

%block {
	@extend %reset;
	display: block;
	width: 100%;
}

.grid-reset {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}
