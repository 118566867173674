/**
 * Landing Page Slider.
 */

.landing-slider {
    @extend %block;
    height: 734px;
    z-index: 1;
    transition: height 0.5s ease;

    &__area {
        position: absolute;
        bottom: 39px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;

        @media screen and (max-width: 1170px) {
            position: relative;
            bottom: auto;
            left: auto;
            right: auto;
        }
    }

    &__inner {
        @extend %block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: max-height 0.5s ease;

        @media screen and (max-width: 1170px) {
            position: relative;
            top: auto;
            left: auto;
            max-height: 0;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &__slide {
        @extend %block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background-repeat: repeat;
        background-position: center;
        transition: opacity 0.5s ease;
        overflow: hidden;

        &-image1,
        &-image2 {
            @extend %reset;
            position: absolute;
            transition: top 0.5s ease,
                bottom 0.5s ease;
        }

        &-image1 {
            left: 20px;
            top: -100%;
            z-index: 1;
            filter: drop-shadow(0 0 50px black);
        }

        &-image2 {
            right: 20px;
            top: -50px;
            z-index: 0;
        }

        &--active {
            opacity: 1;

            .landing-slider__slide-image1 {
                top: 145px;
            }

            .landing-slider__slide-image2 {
                bottom: 0;
            }
        }
    }

    .container:not(.landing-slider__area) {
        z-index: 2;
        position: relative;
    }

    .container:not(.landing-slider__area),
    &__slide,
    &__inner {
        height: 100%;

        @media screen and (max-width: 1170px) {
            height: auto;
        }
    }

    .row {
        width: 100%;

        @media screen and (max-width: 1170px) {
            width: auto;
        }
    }

    &__box {
        @extend %reset;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border: 8px solid #FFFFFF;
        background: #e8e8e8;
        padding: 25px 18px;
        margin-top: 15px;
        transition: margin-top 0.5s ease,
            border 0.5s ease;

        &-header {
            @extend %block;
            text-transform: uppercase;
            color: $dark-blue;
            max-width: 60%;
            transition: max-width 0.5s ease;
            font: 700 20px/1 $title-font;

            @include breakpoint(0, 1170px) {
                max-width: 100%;
            }
        }

        &--0 {
            img {
                top: -8px;
                margin: 0;
                right: -48px !important;
            }
        }

        &-info {
            @extend %block;
            color: #404041;
            font-size: 13px;
            line-height: 18px;
            margin-top: 10px;
            max-width: 60%;
            transition: max-width 0.5s ease;

            @media screen and (max-width: 1170px) {
                max-width: 100%;
            }
        }

        &-image {
            @extend %reset;
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            margin-right: 20px;
            transition: all 0.5s ease;

            @media screen and (max-width: 1170px) {
                opacity: 0;
                right: -1000px !important;
            }
        }

        &-button {
            margin: 10px 0 0;
            max-width: 120px;
            width: max-content;

            @media screen and (max-width: 1170px) {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &--active {
            margin-top: 0;
        }

        @media screen and (max-width:1170px) {
            margin: 20px 0 0;
            border: none;

            &--2 {
                margin-bottom: 20px;
            }

            .landing-slider__box--active {
                margin: 20px 0 0;
            }
        }

        @include breakpoint(0, 700px) {
            text-align: center;
        }
    }

    &-responsive {
        @extend %block;
        display: none;

        &__image {
            @extend %block;
            display: none;

            img {
                @extend %block;
            }

            &--visible {
                display: block;
            }
        }

        @media screen and (max-width:1170px) {
            display: block;
        }
    }

    @media screen and (max-width:1170px) {
        height: auto;
    }
}