/**
 * Header Elements.
 */

body {
    @media (max-width: 599px) {
        padding-top: 141px;

        &.designer-page {
            padding-top: 40px;
        }
    }

    @media (min-width:600px) and (max-width: 991px) {
        padding-top: 188px;
    }
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    >.col,
    >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

header {
    display: flex;
    flex-direction: column;
    position: relative;

    &.header--designer {

        .header-top,
        .header-middle,
        .nav-holder {
            display: none;
        }
    }

    @media (max-width: 991px) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 250;
        width: 100%;
    }
}

.header-top,
.nav-holder {
    z-index: 7;
}

.header-middle {
    z-index: 15;
}

.header-top {
    background: #e8e7e8;
    display: flex;
    height: 60px;
    flex: 1;

    @media (max-width: 991px) {
        order: 3;
        height: auto;
        padding: 7px 0;
        -webkit-box-shadow: 2px 4px 16px -5px rgba(0, 0, 0, 0.68);
        -moz-box-shadow: 2px 4px 16px -5px rgba(0, 0, 0, 0.68);
        box-shadow: 2px 4px 16px -5px rgba(0, 0, 0, 0.68);
    }

    .container {
        @media (max-width:358px) {
            padding: 0;
            max-width: 100%;
        }
    }

    .header-statement {
        height: 60px;
        color: $dark-blue;
        font: 700 15px/1 $title-font;
        text-transform: uppercase;
        user-select: none;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            justify-content: center;
            height: 30px;

            span {
                display: none;
            }
        }

        span {
            margin-left: 4px;
        }

        img,
        i {
            margin-right: 5px;
        }

        i {
            font-size: 23px;
        }

        .social {
            margin-left: auto;
        }
    }

    .social {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 60px;
        width: 100%;
    }

    &-mobile {
        background: $red;
        color: #fff;
        height: 40px;
        font: 300 13px/1 $body-font;

        .flexCenter {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 10px;
        }

        a {
            color: #fff;
            text-decoration: none;

            &.phone,
            &.login {
                display: flex;
                width: 50%;

                i {
                    margin-right: 3px;
                }
            }

            &.phone {
                justify-content: flex-start;
            }

            &.login {
                justify-content: flex-end;
                flex: 1;
            }
        }

        .row {
            width: 100%;
        }

        #basket-tab {
            background: #284678;
            height: 40px;

            a {
                @include flexCenter();
                width: 100%;
                height: 100%;
            }

            i {
                margin-right: 10px;
            }
        }

        #basket-tab-items {
            background: $dark-blue;
            height: 40px;
            @include flexCenter();

            a {
                @include flexCenter();
                width: 100%;
                height: 100%;
            }

            span {
                margin-left: 4px;
                font-size: 11px;
            }
        }

        @media (min-width: 600px) and (max-width:991px) {
            height: 70px;
            font: 700 17px/1 $body-font;

            #basket-tab,
            #basket-tab-items,
            .flexCenter {
                height: 70px;
            }
        }

        @media (max-width:394px) {

            a {
                &.phone {
                    width: 70%;
                }

                &.login {
                    width: 30%;
                }
            }
        }
    }
}

.header-middle {
    display: flex;
    flex: 1;
    padding: 15px;
    background: #fff;
    position: relative;

    @media (max-width: 991px) {
        order: 1;
        padding: 7px;
    }

    .quick-search-mobile {
        position: absolute;
        top: 0;
        left: 0;
        height: 57px;
        display: flex;
        flex-direction: row;
        width: 100%;

        @media (min-width: 600px) and (max-width:991px) {
            height: 73px;

            input,
            button,
            a {
                height: 73px !important;
            }
        }

        input,
        button,
        a {
            border: 0;
            height: 57px;
            box-sizing: border-box;
        }

        input {
            flex: 0 0 70%;
            padding: 0 15px;
            font: 300 14px/1 $body-font;
        }

        button {
            flex: 0 0 15%;
            background: $red;
            color: #fff;
            font: 700 25px/1 $title-font;
        }

        a {
            flex: 0 0 15%;
            background: $dark-blue;
            color: #fff;
            font-size: 25px;
            text-decoration: none;

            @include flexCenter();
        }
    }

    .logo {
        img {
            width: 100%;
        }

        width:301px;
        transition:width 0.5s;

        @media (max-width: 600px) {
            width: 170px;
            height: 43px;

            @include flexCenter();
        }
    }

    #mobile-menu-search {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;

        .circle {
            border-radius: 50% 50%;
            width: 43px;
            height: 43px;
            color: #fff;
            @include flexCenter();
            font-size: 17px;

            &.red {
                background: $red;
                margin: 0 20px 0 0;
            }

            &.blue {
                background: $dark-blue;
            }
        }
    }

    .contact-info,
    .account-info {
        color: $dark-blue;
        font: 700 19px/1 $title-font;
        width: 100%;
        height: 65px;
        text-align: center;
        display: flex;
        align-items: center;

        a {
            color: $dark-blue;
            text-decoration: none;

            &.phone {
                margin-right: 10px;
            }

            i {
                margin: 0 5px 0 0;
            }
        }
    }

    .account-info {
        font-weight: 300;
        justify-content: flex-end;
        margin-left: 5px;
        font-size: 19px;
        position: relative;

        #account-info-basket {
            margin-left: 5px;
        }

        #shopping-bag {
            display: inline-block;
            margin-left: 5px;

            .circle {
                width: 35px;
                height: 35px;
                background: $dark-blue;
                color: #fff;
                border-radius: 50%;
                @include flexCenter();
                font-size: 17px;
                position: relative;

                i {
                    margin: 0;
                }

                .count {
                    $size: 20px;

                    position: absolute;
                    top: -8px;
                    left: -8px;
                    width: $size;
                    height: $size;
                    background: $red;
                    color: #fff;
                    font-size: 11px;
                    font-weight: 700;
                    border-radius: 50%;

                    @include flexCenter();
                }
            }
        }
    }

    #user-login-trigger {
        cursor: pointer;
        margin: 6px 5px 0 0;
    }

    #user-login-trigger,
    #login-dropdown {
        display: inline-block;
        vertical-align: top;
    }

    @media (min-width: 600px) and (max-width:991px) {
        padding: 15px 0;

        .logo {
            width: 200px;
        }
    }

    .container {
        @media (max-width:358px) {
            padding: 0;
            max-width: 100%;
        }
    }
}

.mobile-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $dark-blue;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s;

    @include flexCenter();

    &.open {
        opacity: 1;
        z-index: 300;
    }

    .close {
        background: #fff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 25px;
        color: $dark-blue;
        position: absolute;
        top: 48px;
        right: 29px;
        text-decoration: none;

        @include flexCenter();
    }

    ul {
        padding: 0;
        margin: 0;
        width: 190px;

        li {
            font: 700 18px/1 $title-font;
            margin: 10px 0;
            text-transform: uppercase;
            display: block;

            a {
                color: #fff;
                text-decoration: none;
            }

            .letters {
                width: 190px;
                margin: 10px 0 0 0;

                .letter-item {
                    display: inline-block;
                    vertical-align: top;
                    width: 18px;
                    height: 18px;
                    padding: 2px;
                    border: 1px solid $dark-blue-hover;
                    text-align: center;
                    margin-bottom: 2px;

                    &.active {
                        background: $red;
                    }

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }

                .letter-link-set {
                    border-top: 1px solid $dark-blue-hover;
                    border-bottom: 1px solid $dark-blue-hover;
                    padding: 10px 0;
                    margin: 10px 0;

                    a {
                        text-transform: none;
                        display: block;
                        font-weight: 300;
                        font-size: 14px;
                        margin: 4px 0;
                    }
                }
            }
        }
    }
}

.nav-holder {
    display: flex;
    flex: 1;
    order: 4;
    background: $dark-blue;
    height: 60px;

    @media (max-width: 991px) {
        display: none;
    }

    nav {
        display: flex;
        flex: 1;
        align-items: center;
        height: 60px;

        ul {
            li {
                font: 700 19px/1 $title-font;
                display: inline-block;
                vertical-align: middle;
                text-transform: uppercase;
                margin: 0 8px;
                height: 60px;

                a {
                    color: #fff;
                    text-decoration: none;
                    display: block;
                    width: 100%;
                    height: 100%;

                    @include flexCenter();

                    &.home-button {
                        color: #ec1e30;
                    }
                }
            }
        }
    }

    .nav-search {
        justify-content: flex-end;
        display: flex;
        flex: 1;
        position: relative;

        &.open {
            .quick-search {
                display: block;
            }
        }

        .quick-search {
            position: absolute;
            top: 0;
            right: 45px;
            display: none;

            input,
            button {
                height: 35px;
                border: 0;
                margin: 0;
                box-sizing: border-box;
            }

            input {
                padding: 0 15px;
                font: 300 14px/1 $body-font;
            }

            button {
                background: $red;
                color: #fff;
                font: 700 15px/1 $title-font;
                cursor: pointer;
            }
        }
    }

    .search-trigger {
        background: $red;
        color: #fff;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-size: 15px;
        text-decoration: none;

        @include flexCenter();
    }
}

.login-container {
    position: relative;
}

.login-dropdown {
    display: none;
    position: absolute;
    top: -15px;
    right: 0px;
    text-align: left;
    z-index: 30;
    background: #445572;
    color: #fff;
    padding: 15px;
    width: 250px;
    font-size: 13px;

    @media (max-width: 991px) {
        right: auto;
        left: 50%;
        margin-left: -125px;
        top: 25px;
    }

    &--active {
        display: block;
    }

    &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 20px 10px;
        border-color: transparent transparent #445572 transparent;
        position: absolute;
        top: -20px;
        right: 134px;
    }

    a {
        color: #fff !important;
        font-size: 12px;
    }

    label,
    input,
    button,
    a {
        display: block !important;
        width: 100%;
        max-width: 100%;
        margin: 0 0 5px 0;
        box-sizing: border-box;
    }

    input {
        padding: 7px;
    }
}


.nav-submenu {
    @extend %reset;
    display: flex;
    width: 100%;
    position: absolute;
    top: -1000px;
    left: 0;
    z-index: 5;
    transition: top 0.5s ease;

    @media (max-width: 992px) {
        display: none;
    }

    &-categories {
        @extend %block;
        display: none;

        &-subnav {
            @extend %block;

            &__item {
                @extend %block;
                margin-top: 3px;

                a {
                    @extend %block;
                    color: $dark-blue;
                    text-decoration: none;
                    // font-size: 13px;
                    // line-height: 1.3;
                    // font-family: 'Dosis', sans-serif;
                    // font-weight: bold;
                    font: bold 13px/1.3 $title-font;
                    transition: color 0.5s ease;

                    &:hover,
                    &:active {
                        color: $blue-hover;
                        text-decoration: underline;
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        &__item {
            @extend %reset;
            display: inline-block;
            vertical-align: top;
            width: calc(50% - 35px);
            margin-left: 35px;

            &-image {
                @extend %block;
                border: 1px solid #CCCCCC;
                text-decoration: none;

                img {
                    @extend %block;
                }
            }

            &-title {
                @extend %block;
                text-align: center;
                padding-top: 18px;
                text-decoration: none;
                text-transform: uppercase;
                color: $dark-blue;
                font: 18px/1 $title-font;
            }
        }
    }

    &-items {
        @extend %block;

        &__item {
            @extend %block;
            width: calc(100% - 23px - 15px);
            border-bottom: 1px solid #d3d3d3;
            color: #7c7c7c;
            text-transform: uppercase;
            padding: 12px 0;
            padding-left: 15px;
            text-decoration: none;
            font: 18px/1 $title-font;

            i {
                display: inline-block;
                position: absolute;
                right: 5px;
                font-size: 25px;
                line-height: 25px;
                top: 50%;
                margin-top: -12.5px;
                height: 25px;
            }

            &:first-child {
                border-top: 1px solid #d3d3d3;
                margin-top: -1px;
            }

            &:hover,
            &:active,
            &--active {
                width: calc(100% - 15px + 1px);
                cursor: pointer;
                color: $dark-blue;
                background: #FFFFFF;
                border-color: #dfdfdf;
                border-top: 1px solid #dfdfdf;
                margin-top: -1px;

                i {
                    right: 29px;
                }
            }
        }
    }

    .container {
        display: flex;
        flex-direction: row;
    }

    &__left,
    &__right {
        @extend %reset;
        padding: 30px 0;
        padding-bottom: 60px;
    }

    &__left {
        width: 20%;
        background: #e8e7e8;
        display: block;
        padding: 30px 25px 0;
        color: $dark-blue;

        // &:before {
        //     @extend %block;
        //     position: absolute;
        //     width: 2000px;
        //     height: 100%;
        //     right: 100%;
        //     top: 0;
        //     background: #f3f3f3;
        //     box-shadow: inset 0 4px 0 0 rgba(0, 0, 0, 0.19);
        //     content: '';
        // }

        .left-title {
            text-transform: uppercase;
            font: 700 20px/1 $title-font;
            padding: 0 0 15px;
            border-bottom: 1px solid #a7afbd;

            i {
                color: #e92237;
            }
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                text-transform: uppercase;
                border-bottom: 1px solid #a7afbd;

                a {
                    color: $dark-blue;
                    font: 700 16px/1 $title-font;
                    display: block;
                    padding: 10px 0;
                    text-decoration: none;
                }
            }
        }
    }

    &__right {
        flex-grow: 1;
        background: #fff;
    }

    &--visible {
        top: 100%;
    }

    @include breakpoint(0, 1045px) {
        display: none !important;
    }
}

.nav-section {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    width: 150px;

    .nav-heading {
        color: $dark-blue;
        font: 700 18px/1 $title-font;
        margin: 0 0 10px 0;
    }

    &__links {
        padding: 0;
        margin: 0 0 20px 0;

        li {
            margin: 0 0 5px 0;

            a {
                font: 14px/1 $title-font;
                color: $dark-blue;
                text-decoration: none;
            }
        }
    }
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

@media (min-width: 1200px) {
    .visible-lg {
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .visible-md {
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .visible-sm {
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
}

@media (max-width: 767px) {
    .container {
        padding: 0 15px;
    }

    .visible-xs {
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .hidden-xs {
        display: none !important;
    }
}