/**
 * Mixins.
 */

@mixin appearance($appearance) {
	-webkit-appearance: $appearance;
	   -moz-appearance: $appearance;
	        appearance: $appearance;
}

@mixin border-radius-tl($radius) {
	-webkit-border-top-left-radius: $radius;
	   -moz-border-top-left-radius: $radius;
	    -ms-border-top-left-radius: $radius;
	     -o-border-top-left-radius: $radius;
	        border-top-left-radius: $radius;
}

@mixin border-radius-tr($radius) {
	-webkit-border-top-right-radius: $radius;
	   -moz-border-top-right-radius: $radius;
	    -ms-border-top-right-radius: $radius;
	     -o-border-top-right-radius: $radius;
	        border-top-right-radius: $radius;
}

@mixin border-radius-bl($radius) {
	-webkit-border-bottom-left-radius: $radius;
	   -moz-border-bottom-left-radius: $radius;
	    -ms-border-bottom-left-radius: $radius;
	     -o-border-bottom-left-radius: $radius;
	        border-bottom-left-radius: $radius;
}

@mixin border-radius-br($radius) {
	-webkit-border-bottom-right-radius: $radius;
	   -moz-border-bottom-right-radius: $radius;
	    -ms-border-bottom-right-radius: $radius;
	     -o-border-bottom-right-radius: $radius;
	        border-bottom-right-radius: $radius;
}

@mixin transition($transition...) {
	-webkit-transition: $transition;
	   -moz-transition: $transition;
	    -ms-transition: $transition;
	     -o-transition: $transition;
	        transition: $transition;
}

@mixin transform($transform) {
	-webkit-transform: $transform;
	   -moz-transform: $transform;
	    -ms-transform: $transform;
	     -o-transform: $transform;
	        transform: $transform;
}

@mixin animation($animation) {
	-webkit-animation: $animation;
	   -moz-animation: $animation;
	    -ms-animation: $animation;
	     -o-animation: $animation;
	        animation: $animation;
}

@mixin box-shadow($shadow...) {
	-webkit-box-shadow: $shadow;
	   -moz-box-shadow: $shadow;
	        box-shadow: $shadow;
}

@mixin text-shadow($shadow) {
	-webkit-text-shadow: $shadow;
	   -moz-text-shadow: $shadow;
	        text-shadow: $shadow;
}

@mixin font-smoothing($smoothing) {
	-webkit-font-smoothing: $smoothing;
	   -moz-font-smoothing: $smoothing;
	    -ms-font-smoothing: $smoothing;
	        font-smoothing: $smoothing;
}

@mixin appearance($appearance) {
    -webkit-appearance: $appearance;
       -moz-appearance: $appearance;
            appearance: $appearance;
}

@mixin flexCenter() {
    align-items:center;
    justify-content:center;
    display:flex;
}

@mixin no-select() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

@mixin opacity($opacity) {
	opacity: $opacity;
	$opacity-ie: $opacity * 100;
	filter: alpha(opacity=$opacity-ie); // IE8
}

@mixin breakpoint($min: 0, $max: 0) {
    $type: type-of($min);

    @if $type == string {
        @if $min == xs {
            @media (max-width: 768px) {
                @content;
            }
        }
        @else if $min == sm {
            @media (max-width: 992px) {
                @content;
            }
        }
        @else if $min == md {
            @media (max-width: 1200px) {
                @content;
            }
        }
    }
    @else if $type == number {
        $query: 'all' !default;

        @if $min != 0 and $max != 0 {
            $query: '(min-width: #{$min}) and (max-width: #{$max})';
        }
        @else if $min != 0 and $max == 0 {
            $query: '(min-width: #{$min})';
        }
        @else if $min == 0 and $max != 0 {
            $query: '(max-width: #{$max})';
        }
    }
}

@mixin montserrat($type: '', $weight: '') {
    @if $type != '' {
        font-family: 'Montserrat #{$type}', sans-serif;
    }
    @else {
        font-family: 'Montserrat Regular', sans-serif;
    }
    @if $weight != '' {
        font-weight: $weight;
    }
    @else {
        font-weight: normal;
    }
}

@mixin bebas($type: '', $weight: '') {
    @if $type != '' {
        font-family: 'BebasNeue #{$type}', sans-serif;
    }
    @else {
        font-family: 'BebasNeue Regular', sans-serif;
    }
    @if $weight != '' {
        font-weight: $weight;
    }
    @else {
        font-weight: normal;
    }
}
