/**
 * Partials
 */

@import '../partials/browsersync';
@import '../partials/resets';
@import '../partials/icons';
@import '../partials/helpers';
@import '../partials/fonts';
@import '../partials/buttons';
@import '../partials/social';
@import '../partials/landing-slider';
@import '../partials/landing-section';
@import '../partials/landing-sections';
@import '../partials/landing-gallery';
@import '../partials/landing-gallery2';
@import '../partials/landing-newsletter';
@import '../partials/featured-products';
@import '../partials/landing-boxes';
@import '../partials/landing-description';
@import '../partials/landing-info';
@import '../partials/product-page';
@import '../partials/product-slider';
@import '../partials/product-list';
@import '../partials/category';
@import '../partials/scroll';
@import '../partials/page-header';
@import '../partials/gallery-list';
@import '../partials/gallery-info';
@import '../partials/gallery-slider';
@import '../partials/large-header';
@import '../partials/page';
@import '../partials/quote';
@import '../partials/basket';
@import '../partials/account';
@import '../partials/contact';
@import '../partials/checkout';
@import '../partials/date';
@import '../partials/black-friday';
@import '../partials/testimonials';
@import '../partials/weddings';
