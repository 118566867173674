/**
 * Landing Sections.
 */

.landing-section {
    @extend %block;
    padding: 35px 0;

    &__box {
        @extend %block;
        margin-top: 35px;

        &:first-child {
            margin-top: 0;
        }

        &--bordered {
            position: relative;
            max-width: 100%;

            &:after {
                border: 1px solid white;
                content: '';
                position: absolute;
                width: calc(100% - 30px);
                height: calc(100% - 30px);
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }

            img.solid {
                @extend %block;
            }
        }

        &--margin {
            margin-top: 35px !important;
        }

        &--image {
            img {
                @extend %block;
            }
        }
    }
}