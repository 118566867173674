/**
 * Scroll-to-top.
 */
.scroll-top {
    @extend %block;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    width: 39px;
    height: 39px;
    border: 1px solid #c4c4c4;
    position: fixed;
    bottom: -41px;
    z-index: 10;
    color: $blue;
    font-size: 34px;
    transition: bottom 0.5s ease,
        background 0.5s ease,
        color 0.5s ease;

    &:hover,
    &:active {
        background: $blue;
        color: #FFFFFF;
        cursor: pointer;
    }

    .fa {
        margin-top: -3px;
    }

    &--left {
        left: 20px;
    }

    &--right {
        right: 20px;
    }

    &--visible {
        bottom: 100px;
    }

    @media (max-width:1000px) {
        display: none;
    }
}
