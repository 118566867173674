
.landing-info {
    @extend %block;
    width: calc(100% - 80px - 18px);
    border: 9px solid #FFFFFF;
    background: rgba(225, 239, 246, 0.86);
    padding: 40px;
    transition: padding 0.5s ease,
        width 0.5s ease;

    &-container {
        @extend %block;
        padding: 130px 0;
        background: url('/assets/images/landing/info-background.jpg') no-repeat center bottom #FFFFFF;
        transition: padding 0.5s ease;

        @include breakpoint(0, 1000px) {
            padding: 30px 0;
        }
    }

    &__large-image {
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        transition: opacity 0.5s ease,
            right 0.5s ease;

        @include breakpoint(0, 1000px) {
            right: -1000px;
            opacity: 0;
        }
    }

    &__inner {
        @extend %block;
        max-width: 60%;
        transition: max-width 0.5s ease;

        h3 {
            @extend %block;
            color: $blue;
            text-transform: uppercase;
            transition: font-size 0.5s ease,
            line-height 0.5s ease;
            font: 34px/1 $title-font;

            span {
                color: #216379;
            }

            @include breakpoint(0, 1000px) {
                font-size: 26px;
                line-height: 26px;
            }
        }

        img {
            @extend %reset;
            display: inline-block;
            max-width: 100%;
            height: auto;
        }

        p {
            @extend %block;
            font-size: 13px;
            line-height: 19px;
            color: #7c7c7c;
            margin-top: 17px;
            @include montserrat();

            a {
                text-decoration: underline;
                color: #7c7c7c;

                &:hover,
                &:active {
                    text-decoration: underline;
                    color: #5c5959;
                }
            }

            &:first-child {
                margin-top: 0;
            }
        }

        @include breakpoint(0, 1000px) {
            max-width: 100%;
        }
    }

    @include breakpoint(0, 700px) {
        padding: 20px;
        width: calc(100% - 40px - 18px);
        text-align: center;
    }
}
