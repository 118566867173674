
.ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all {
    background: $blue !important;
    border: none !important;
    color: #FFFFFF !important;
    border-radius: 0 !important;
}

.ui-datepicker-next.ui-corner-all,
.ui-datepicker-prev.ui-corner-all {
    border-radius: 0 !important;
    background: transparent !important;
    color: #FFFFFF !important;
    border: none !important;
    cursor: pointer !important;
}

.ui-icon.ui-icon-circle-triangle-e {
    color: #FFFFFF !important;
}

.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all {
    border-radius: 0 !important;
}
