/**
 * Gallery Info section.
 */

.gallery-info {
    @extend %block;
    border: 1px solid #e1e1e1;
    width: calc(100% - 2px);
    margin: 25px 0;

    &-social {
        @extend %reset;
        margin-top: 28px;

        &__item {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            width: 36px;
            height: 36px;
            background: #b2b2b2;
            text-align: center;
            line-height: 36px;
            margin-left: 12px;
            transition: background 0.5s ease;

            &-overlay {
                @extend %block;
                width: 100px;
                height: 30px;
                line-height: 30px;
                padding: 5px;
                border: 1px solid #999999;
                background: #FFFFFF;
                text-align: center;
                z-index: 10;
                position: absolute;
                left: 50%;
                margin-left: -50px;
                top: -32px;
                display: none;
            }

            i {
                color: #FFFFFF;
                font-size: 20px;
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover,
            &:active {
                background: $blue;

                .gallery-info-social__item-overlay {
                    display: block;
                }
            }
        }
    }

    &-actions {
        @extend %block;
        display: flex;
        flex-direction: row;

        &__button {
            @extend %block;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 66px;
            height: 66px;
            background: $blue;
            font-size: 45px;
            line-height: 45px;
            color: #FFFFFF;
            transition: background 0.5s ease;

            &:first-child {
                background: $green;
            }

            &:hover,
            &:active {
                background: $blue-hover;
                cursor: pointer;

                &:first-child {
                    background: $green-hover;
                }
            }
        }

        &__first,
        &__last {
            @extend %block;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &__first {
            flex-grow: 1;
            flex-shrink: 1;
        }

        &__last {
            flex-basis: 66px;
            flex-shrink: 0;
        }
    }

    &__inner {
        @extend %block;
        padding: 20px;
        width: calc(100% - 40px);
        padding-bottom: 70px;
    }

    &__title {
        @extend %block;
        color: $blue;
        text-transform: uppercase;
        font: 20px/1 $title-font;
    }

    &__link {
        margin-top: 15px;
    }

    &__desc {
        @extend %block;

        p {
            @extend %block;
            font-size: 13px;
            line-height: 18px;
            color: #7c7c7c;
            margin-top: 12px;
            @include montserrat();
        }
    }
}
