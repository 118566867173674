/**
 * Product Slider.
 */

.product-slider {
    @extend %block;
    border: 1px solid #cfcfcf;
    border-left: none;
    border-right: none;
    margin-top: 40px;
    padding-top: 15px;
    padding-bottom: 30px;

    &-pagination {
        @extend %block;
        text-align: center;
        margin-top: 15px;
        display: none;

        .button {
            margin: 10px 0;
            height: 34px;
        }

        &__button,
        &__item,
        &__pages {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
        }

        &__button {
            width: 34px;
            height: 34px;
            background: #b2b2b2;
            transition: background 0.5s ease;

            i {
                width: 32px;
                height: 32px;
                line-height: 32px;
                font-size: 32px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                color: #FFFFFF;
            }

            &:hover,
            &:active {
                background: $blue;
                cursor: pointer;
            }

            &--margin-left {
                margin-left: 12px;
            }

            &--margin-right {
                margin-right: 12px;
            }

            &--disabled {
                background: #e8e8e8;
                cursor: default;

                &:hover,
                &:active {
                    background: #e8e8e8;
                    cursor: default;
                }
            }
        }

        &__item {
            font-size: 12px;
            line-height: 12px;
            color: #7b7b7b;
            padding: 0 2px;
            @include montserrat();

            &:hover,
            &:active {
                cursor: pointer;
            }

            &--active {
                color: $blue;
                @include montserrat('Bold');
            }
        }
    }

    &__slide {
        @extend %reset;
        display: flex;
        flex-direction: column;
        width: 220.5px;
        max-width: 220.5px;
        flex-grow: 1;
        border: 1px solid #e0e0e0;
        margin-left: 30px;
        padding: 20px;
        text-align: center;
        white-space: normal;

        &:first-child {
            margin-left: 0;
        }

        &-title {
            @extend %block;
            font-size: 13px;
            line-height: 19px;
            color: #7c7c7c;
            margin-top: 20px;
            text-decoration: none;
            @include montserrat();
        }

        &-price {
            @extend %block;
            font-size: 20px;
            line-height: 20px;
            color: $blue;
            margin-top: 10px;
            @include montserrat('Bold');
        }

        &-image {
            @extend %block;
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: center;

            img {
                @extend %block;
            }
        }

        &:hover {
            .item-overlay {
                opacity: 1;
            }
        }
    }

    &__container {
        @extend %block;
        margin-top: 15px;
        overflow: hidden;
        max-width: 100%;
        display: none;
        margin-left: auto;
        margin-right: auto;
    }

    &__inner,
    &__placeholder {
        @extend %reset;
        display: flex;
        flex-direction: row;
        width: 200000px;
        white-space: nowrap;
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        transition: left 0.25s ease;
    }

    &__placeholder {
        opacity: 0;
        z-index: -1;
    }

    &-tabs {
        @extend %block;
        text-align: center;

        &__item {
            @extend %reset;
            display: inline-block;
            vertical-align: middle;
            color: #FFFFFF;
            text-transform: uppercase;
            height: 36px;
            padding: 0 20px;
            background: #b2b2b2;
            margin-left: 10px;
            transition: all 0.5s ease;
            // font-family: 'Dosis', sans-serif;
            // font-size: 17px;
            // line-height: 36px;
            font: 17px/36px $title-font;

            &:first-child {
                margin-left: 0;
            }

            &:hover,
            &:active,
            &--active {
                background: $blue;
                cursor: pointer;
            }
        }
    }
}
