/**
 * Variables
 */
$social-size-small: 30px;
$social-size-large: 50px;

// Fonts
$title-font: 'Dosis',sans-serif;
$body-font: 'Montserrat',sans-serif;

/* Colours */
$dark-blue: #263755;
$dark-blue-hover: lighten($dark-blue, 20%);
$blue: lighten($dark-blue, 10%);
$blue-hover: lighten($dark-blue-hover, 5%);
$red:#EB1E2F;
$red-hover: lighten($red, 10%);

$green: #8cc648;
$green-hover: #7eb23b;

$border-color: #e8e8e8;
$white-hover: darken(white, 5%);

// $light-blue: #5baec7; // not used
// $light-blue-hover: #6bc4df; // not used
// $darker-blue: #216379; //not used
// $pink: #eb4c99; //not used

/* CMS Variables */
$cms-header-height: 75px;

/* CMS Colours */
$cms-dark-grey: #414141;
$cms-dark-grey-bg: lighten($cms-dark-grey, 15%);
$cms-primary: #95b12f;
$cms-primary-hover: #819c21;
$cms-primary-light: #a5c23c;
$cms-accent: #30a2ae; 
$cms-accent-hover: #1a8691;
$cms-accent-light: $blue;

@import '../modules/mixins';