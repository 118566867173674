/**
 * Buttons.
 */

.button {
    @extend %reset;
    background: $red;
    text-transform: uppercase;
    color: #FFFFFF;
    height: 39px;
    text-align: center;
    padding: 0 20px;
    text-decoration: none;
    transition: all 0.5s ease;
    font: 15px/1 $title-font;
    
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 160px;

    z-index: 5;

    i {
        margin-left: 5px;
    }

    &:hover,
    &:active {
        background: $red-hover;
        cursor: pointer;
        text-decoration: none;
    }

    &--green {
        background:#04aae7;
        opacity:1;

        &:hover,
        &:active {
            background:#04aae7;
            opacity:0.8;
        }
    }

    &--gray {
        background: #b2b2b2;

        &:hover,
        &:active {
            background: $blue;
        }
    }

    // &--block {
    //     display: block;
    //     width: 100%;
    // }

    &--no-padding {
        padding: 0;
    }

    &--thin {
        height: 34px;
        line-height: 34px;
    }

    &--small {
        font-size: 12px;
        height: 29px;
        line-height: 29px;
    }

    &--large {
        font-size: 17px;
    }

    &--massive {
        height: 60px;
        font-weight:700;
        font-size:19px;
        line-height:1;

        @include breakpoint(0, 350px) {
            font-size: 18px;
        }
    }

    &--disabled {
        background: #BBBBBB;
        cursor: default;

        &:hover,
        &:active {
            background: #BBBBBB;
            cursor: default;
        }
    }

    &--left {
        i {
            margin-right: 5px;
            margin-left: 0;
        }
    }

    &--dark-blue {
        background:$dark-blue;

        &:hover {
            background:$dark-blue-hover;
        }
    }
}