/**
 * Account Management.
 */

.account {
    &-container {
        @extend %block;
        padding-bottom: 30px;
    }

    &-table {
        @extend %reset;
        width: 100%;
        text-align: left;
        margin-top: 15px;

        @media screen and (min-widht:768px) {
            margin-top: 30px;
        }

        thead {
            width: 100%;
            border: 1px solid $blue;

            th {
                background: $blue;
                color: #FFFFFF;
                @include montserrat('Bold');
                padding: 10px 2px;
                font-size: 12px;

                @media screen and (min-width:798px) {
                    padding: 15px;
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }

        tbody {
            width: 100%;

            tr {
                border: 1px solid #d6d6d6;
                transition: background 0.5s ease;

                td {
                    color: #7c7c7c;
                    @include montserrat();
                    padding: 5px;
                    font-size: 12px;

                    @media screen and (min-width:798px) {
                        padding: 15px;
                        font-size: 13px;
                        line-height: 19px;
                    }

                    .button {
                        @media screen and (max-width:768px) {
                            padding: 5px;
                            font-size: 12px;
                            height: unset;
                        }

                        span {
                            margin-left: 3px;

                            @media screen and (max-width:768px) {
                                display: none;
                            }
                        }
                    }
                }

                &:hover {
                    background: #F0F0F0;
                }
            }
        }
    }

    &-message {
        @extend %block;
        padding: 30px 0;


        p {
            @extend %block;
            font-size: 15px;
            line-height: 22px;
            color: #7c7c7c;
            text-align: center;
            @include montserrat();
        }

        &--success p {
            color: #09c909;
        }

        &--error p {
            color: #bf0b0b;
        }
    }

    &-input {
        @extend %block;
        margin-top: 10px;

        &__label {
            @extend %block;
            font-size: 13px;
            line-height: 13px;
            color: #7c7c7c;
            @include montserrat();
        }

        &__input,
        &__select {
            @extend %block;
            width: calc(100% - 32px);
            border: 1px solid #c9c9c9;
            height: 36px;
            line-height: 36px;
            padding: 0 15px;
            font-size: 13px;
            color: #757575;
            margin-top: 5px;
            -webkit-appearance: none;
            border-radius: 0;
            @include montserrat();
        }

        &__select {
            width: 100%;
            background: url('/assets/images/select.jpg') no-repeat center right #FFFFFF;
        }

        &:first-child {
            margin-top: 0;
        }
    }

    &-section {
        @extend %block;
        margin-top: 15px;

        @media screen and (min-width:768px) {
            margin-top: 30px;
        }

        &__button {
            margin-top: 15px;

            &--col {
                margin-top: 26px;
            }
        }

        &__header {
            @extend %block;
            width: calc(100% - 60px);
            padding: 15px 30px;
            color: #FFFFFF;
            background: $blue;
            font-size: 13px;
            line-height: 13px;
            @include montserrat('Bold');
        }

        &__inner {
            @extend %block;
            border: 1px solid #d6d6d6;
            padding: 15px;
            width: calc(100% - ((15px *2) + 2px));

            @media screen and (min-width:798px) {
                width: calc(100% - 72px);
                padding: 35px;
            }

            p {
                @extend %block;
                color: #7c7c7c;
                font-size: 13px;
                line-height: 20px;
                margin-top: 10px;
                @include montserrat();

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &-content {
        @extend %block;
        border: 1px solid #e1e1e1;
        padding: 15px;
        width: calc(100% - 32px);
        position: relative;

        @media screen and (min-width:768px) {
            width: calc(100% - 82px);
            padding: 40px;
        }

        &__header {
            @extend %block;
            text-transform: uppercase;
            color: $blue;
            font: 20px/1 $title-font;
        }

        &__user {
            @extend %reset;
            font-size: 13px;
            line-height: 13px;
            color: $blue;
            @include montserrat('Bold');

            .fa {
                font-size: 20px;
                margin-right: 3px;
            }

            a {
                color: $blue;
                margin-left: 15px;
                transition: color 0.5s ease;

                &:hover,
                &:active {
                    color: $green;
                }
            }
        }
    }

    &-tabs {
        @extend %block;
        display: flex;
        flex-direction: row;

        &__tab {
            @extend %reset;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 135px;
            margin-top: 10px;
            background: $green;
            flex-grow: 1;
            flex-basis: 0;
            text-decoration: none;
            border-bottom: 10px solid $green-hover;
            border-bottom-width: 0;
            transition: border-bottom-width 0.25s ease,
                margin-top 0.25s ease;

            &-icon {
                @extend %reset;

                .fa {
                    color: #FFFFFF;
                    font-size: 25px;

                    @media screen and (min-width:768px) {
                        font-size: 45px;
                    }
                }
            }

            &-text {
                @extend %reset;
                color: #FFFFFF;
                text-transform: uppercase;
                margin-top: 10px;
                font: 15px/1 $title-font;
                text-align: center;

                @media screen and (min-width:768px) {
                    font-size: 20px;
                }
            }

            &:nth-child(2n+1) {
                background: $blue;
                border-bottom-color: $blue-hover;
            }

            &:hover,
            &:active,
            &--active {
                cursor: pointer;
                margin-top: 0;
                border-bottom-width: 10px;
                text-decoration: none;
            }

            @include breakpoint(0, 880px) {
                flex-grow: 0;
                flex-basis: auto;
                margin-top: 0;

                &:hover,
                &:active,
                &--active {
                    border-bottom-width: 0;
                }
            }
        }

        @include breakpoint(0, 880px) {
            flex-direction: column;
        }
    }
}