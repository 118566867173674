/**
 * Helper Classes
 */

.no-padding-left {
	padding-left: 0 !important;
}

.no-padding-right {
	padding-right: 0 !important;
}

.no-padding-top {
	padding-top: 0 !important;
}

.no-padding-bottom {
	padding-bottom: 0 !important;
}

.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.xs-margin-top {
    @include breakpoint(0, 768px) {
        margin-top: 35px;
    }
}

.col-flex {
    display: flex;
}

.full-height {
    height: 100%;
}

.image-block {
    @extend %block;
    max-width: 100%;
}

.container {
    width: 1140px;
    max-width: calc(100% - 40px);
    margin: 0 auto;
    padding: 0 20px;

    &--no-padding {
        padding: 0;
        max-width: 100%;
    }
}

.hide,
.hidden {
	display: none !important;
}

@media only screen and (max-width: 62em) {
    .hidden-sm {
        display: none !important;
    }
}

.pull-right {
	float: right;
}

.pull-left {
	float: left;
}

.offscreen {
	position: fixed;
	top: -20000px;
	left: -20000px;
    opacity: 0;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.vertical-center:before {
    @extend %reset;
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 100%;
    content: '';
}

.flex-display,
.flex-col,
.flex-row,
.flex-center {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.flex-fill {
    flex: 1 0 auto;
}

.flex-center {
    align-items: center;
    justify-content: center;
}
