/**
 * Container Elements.
 */

.page-container {
    flex-grow: 1;
}

#ui-datepicker-div {
    font-size: 14px !important;
}

.site-alert {
    @extend %block;
    width: calc(100% - 30px);
    margin-top: 20px;
    padding: 15px;
    background: #000000;
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    line-height: 18px;

    a,
    a:hover,
    a:active {
        color: #FFFFFF;
    }

    &--success {
        background: $green;
    }

    &--error {
        background: #9d1414;
    }
}
